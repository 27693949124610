import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const processSubscription = (data) => {
    return axios.post(`/subscribe-to-plan`, {
        data: data,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const processBooking = (data) => {
    return axios.post(`/pay-booking`, {
        data: data,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const PaymentService = {
    processSubscription,
    processBooking
}

export default PaymentService;