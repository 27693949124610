import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LocalisationInput from "./LocalisationInput";
import { toast } from "react-toastify";

import { formatPrice } from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";

import jsPDF from 'jspdf';

const DetailsSolForm = ({ sol, material_names }) => {
  const btnRef = useRef();

  const [materialInfos, setMaterialInfos] = useState([]);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text(`Informations sur le sol : ${sol?.name}`, 10, 10);
    doc.text(`Matériaux :`, 15, 25);

    for (let index = 0; index < materialInfos.length; index++) {
      doc.text(`${materialInfos[index]}`, 20, 35 + index * 10);
    }
    
    doc.text(`Email : ${sol?.email}`, 15, 45 + materialInfos.length * 10);
    doc.text(`Adresse postale : ${sol?.address}, ${sol?.postal_code}`, 15, 55 + materialInfos.length * 10);
    doc.text(`Distance : À ${sol?.distance && Number(sol?.distance).toFixed(2)} km de votre position`, 15, 65 + materialInfos.length * 10);
    doc.save(`${sol?.name}.pdf`);
  }

  const customFilter = (data, search) => {
    
    if (data) {
      let result = data?.filter((val) => (
        val.id == search
      ));
  
    return result[0]?.name
    }

}

useEffect(()=>{
  console.log("test");
  if (sol && material_names) {

    let array = []

    for (let index = 0; index < JSON.parse(sol?.material_info)?.length; index++) {
      array[index] =  customFilter(material_names, JSON.parse(sol?.material_info)?.[index]?.materialName) + ` ${JSON.parse(sol?.material_info)?.[index].quantity} tonnes` + ` ${formatPrice(JSON.parse(sol?.material_info)?.[index].price)}`
      
    }
    
    setMaterialInfos([...array])
  }
},[sol, material_names])

console.log(sol);

  return (
    <>
      <button
        className="d-none"
        ref={btnRef}
        data-bs-toggle="modal"
        data-bs-target="#detailsSolModal"
      ></button>
      <div
        className="modal fade overflow-hidden"
        id="detailsSolModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="w-100 d-flex align-items-center justify-content-center pt-5">
          <motion.div
            style={{
              cursor: "pointer",
              fontSize: "40px",
              width: "60px",
              height: "60px",
              background: "#fff",
              color: "#3da663",
            }}
            className="mb-lg-0 mb-5 p-1 rounded-circle d-flex justify-content-center align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#detailsSolModal"
            whileTap={{ scale: 1.1 }}
          >
            <span aria-hidden="true">&times;</span>
          </motion.div>
        </div>
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content position-relative pt-1  log_ca-form"
            style={{ top: "-100px" }}
          >
            <div className="modal-body p-4 px-5 pb-0">
              <h4 className="modal-title text__smoke" id="exampleModalLabel">
                informations sur le sol
              </h4>

              {sol && material_names ? <div className="d-flex mb-3 justify-content-center align-items-center flex-column gap-2">
                <div className="w-100 text-left mt-2 d-flex gap-2 align-items-center">
                  <small className="fw-bold text-uppercase text-muted">
                    Nom :
                  </small>
                  <small className="text-muted text-primary">
                    
                    {sol?.name}
                  </small>
                </div>

                <div className="w-100 text-left gap-2 d-flex flex-row align-items-center">
                  <div style={{whiteSpace: "nowrap"}} className="fw-bold text-uppercase text-muted">
                    matériaux :
                  </div>
          
                  <div className="text-left d-flex flex-column">
                      
                  {JSON.parse(sol?.material_info)?.map((sol, index) => (
                        <div key={index} className='contaminant text-muted text-primary ' style={{ fontSize: "9.5px"}}>
                          {customFilter(material_names, sol.materialName)} {sol.quantity} tonnes {formatPrice(sol.price)} / t
                          {index == 0 && " ,"}
                        </div>
                          
                      ))} 
                  </div>

                </div>

                <div className="w-100 text-left d-flex gap-2 align-items-center">
                  <small className="fw-bold text-uppercase text-muted">
                    email :
                  </small>
                  <small className="text-muted text-primary">
                    
                    {sol?.email}
                  </small>
                </div>

                <div className="w-100 text-left d-flex gap-2 align-items-center">
                  <small className="fw-bold text-uppercase text-muted">
                    adresse postale :
                  </small>
                  <small className="text-muted text-primary">
                    
                  {sol.address}, {sol.postal_code}
                  </small>
                </div>

                <div className="w-100 text-left d-flex gap-2 align-items-center">
                  <small className="fw-bold text-uppercase text-muted">
                    distance :
                  </small>
                  <small className="text-muted text-primary">
                    
                    À {sol?.distance && Number(sol?.distance).toFixed(2)} km de votre position
                  </small>
                </div>

                
              </div> 
            : <CircularLoader />
            }
            </div>

            <div className="modal-footer d-flex align-items-center justify-content-center">
              <motion.button
                whileTap={{ scale: 1.1 }}
                type="button"
                className="btn search__btn rounded-pill"
                onClick={handleDownloadPDF}
              >
                Télécharger
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsSolForm;
