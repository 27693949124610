import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLanguage from "../hook/useLanguage";
import { getCurrentUser } from "../redux/slices/userSlice";
import "./../styles/ProfilePage.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddSiteForm from "../components/form/AddSiteForm";
import AddAvailableSolForm from "../components/form/AddAvailableSolForm";
import ProfileContainer from "./../components/ProfileContainer";
import Profil_Form from "../components/form/Profil_Form";
import { Tab, Tabs } from "react-bootstrap";
import { GiPositionMarker } from "react-icons/gi";
import { TbWashDrycleanOff } from "react-icons/tb";
import { getConcentrations } from "../redux/slices/concentrationSlice";
import { getContaminants } from "../redux/slices/contaminantSlice";
import { editSite, getSites, removeSite } from "../redux/slices/siteSlice";


import { getMaterialName } from "../redux/slices/materialNameSlice";
import {
  editAvailableSol,
  getAvailableSol,
  removeAvailableSol,
} from "./../redux/slices/availableSolSlice";
import NoData from '../components/NoData';
import EditSiteForm from "../components/form/EditSiteForm";
import { formatPrice } from '../utils/utils';
import ImageViewer from '../components/ImageViewer';
import CircularLoader from '../components/loader/CircularLoader';
import { Modal } from '../components/Modal';

import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import EditAvailableSolForm from "../components/form/EditAvailableSolForm";



const ProfilePage = () => {
  const { index } = useParams();
  const userData = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("pending");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onAddSite, setOnAddSite] = useState(false);
  const [onAddSol, setOnAddSol] = useState(false);
  const siteData = useSelector((state) => state.site);
  const availableSolData = useSelector((state) => state.available_sol);
  const contaminantData = useSelector((state) => state.contaminant);
  const concentrationData = useSelector((state) => state.concentration);
  const materialNameData = useSelector((state) => state.material_info);

  const [userAvailableSols, setUserAvailableSols] = useState([]);
  const [userSites, setUserSites] = useState([]);

  const [onEdit, setOnEdit] = useState(false);
  const [q, setQ] = useState(null);

  const [onEditSol, setOnEditSol] = useState(false);

  const [imageViewer, setImageViewer] = useState(false);
    const [confirm, setConfirm] = useState(null);


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (!userData) dispatch(getCurrentUser());
    if (parseInt(index) < 1 || parseInt(index) > 2 || !parseInt(index))
      navigate("/profile");
    if (!index) {
      navigate("/profile");
    }
  }, [index]);

  useEffect(() => {
    if (siteData === null) dispatch(getSites());
    if (contaminantData === null) dispatch(getContaminants());
    if (concentrationData === null) dispatch(getConcentrations());
    if (availableSolData === null) dispatch(getAvailableSol());
    if (materialNameData === null) dispatch(getMaterialName());
  }, []);


  useEffect(() => {
    const filteredavailableSolData = availableSolData?.filter(
      (item) => item.user_id === userData?.id
    );
    const filteredSiteData = siteData?.filter(
      (item) => item.user_id === userData?.id
    );

    setUserAvailableSols(filteredavailableSolData);
    setUserSites(filteredSiteData);
  }, [availableSolData,siteData, userData]);

  
  const siteColumns = [
    {
        name: 'Id',
        selector: (row) => {
            return row.site_id
        }
    },
    {
        name: 'Nom',
        selector: (row) => {
            return row.name
        }
    },
    {
        name: 'Type',
        selector: (row) => {
            return row.type
        }
    },
    {
        name: 'Addresse',
        selector: (row) => {
            return row.address
        }
    },
    {
        name: 'Ville',
        selector: (row) => {
            return row.city
        }
    },
    {
        name: 'Prix Unitaire',
        selector: (row) => {
            return formatPrice(row.unit_price)
        },
        sortable: true,
    },
    {
        name: 'Code Postal',
        selector: (row) => {
            return row.postal_code
        }
    },
    {
        name: 'Actif',
        selector: (row) => {
            return <label className="switch">
                <input type="checkbox" onClick={(e) => handleActiveSite(e, row)} checked={parseInt(row.active) === 1} />
                <div></div>
            </label>
        },
        sortable: true,
        sortFunction: (a, b) => {
            return parseInt(a.active) - parseInt(b.active)
        }
    },
    {
        name: 'Actions',
        selector: (row) => {
            return <div className="d-flex justify-content-around w-100">
                <button onClick={() => { setImageViewer(row.images) }} title="visualisez les images du site" style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }} className="actionBtn"><i className="fa fa-eye" aria-hidden="true"></i></button>
                <button title="editer le site" onClick={() => handleEditSite(row)} style={{ color: "#f5f5f5", backgroundColor: "#22aa22" }} className="mx-1 actionBtn"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                <button title="supprimer le site" onClick={(e) => handleDeleteSite(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#aa2222" }} className="mx-1 actionBtn"><i className="fa fa-trash" aria-hidden="true"></i></button>
            </div>

        }
    }

];


const SolsColumns = [
  {
      name: 'Id',
      selector: (row) => {
          return row.id
      }
  },
  {
      name: 'Nom',
      selector: (row) => {
          return row.name
      }
  },
  {
      name: 'Addresse',
      selector: (row) => {
          return row.address
      }
  },
  {
      name: 'Ville',
      selector: (row) => {
          return row.city
      }
  },

  {
      name: 'Code Postal',
      selector: (row) => {
          return row.postal_code
      }
  },
  {
      name: 'Actif',
      selector: (row) => {
          return <label className="switch">
              <input type="checkbox" onClick={(e) => handleActiveSol(e, row)} checked={parseInt(row.active) === 1} />
              <div></div>
          </label>
      },
      sortable: true,
      sortFunction: (a, b) => {
          return parseInt(a.active) - parseInt(b.active)
      }
  },
  {
      name: 'Actions',
      selector: (row) => {
          return <div className="d-flex justify-content-around w-100">
              <button onClick={() => { setImageViewer(row.images) }} title="visualisez les images du sol" style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }} className="actionBtn"><i className="fa fa-eye" aria-hidden="true"></i></button>
              <button title="editer le sol" onClick={() => handleEditSol(row)} style={{ color: "#f5f5f5", backgroundColor: "#22aa22" }} className="mx-1 actionBtn"><i className="fa fa-pencil" aria-hidden="true"></i></button>
              <button title="supprimer le sol" onClick={(e) => handleDeleteSol(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#aa2222" }} className="mx-1 actionBtn"><i className="fa fa-trash" aria-hidden="true"></i></button>
          </div>

      }
  }

];

const customTextFilter = (data, search) => {
  if (search === null) {
      return data
  }
  search = search.toLowerCase()
  return data.filter((val, key) => {

      return val?.name && (val.name.toLowerCase().includes(search) || val.address.toLowerCase().includes(search) || val.city.toLowerCase().includes(search))
  })
}

const handleEditSite = (site) => {
  setOnEdit(site)
}

const handleEditSol = (sol) => {
  setOnEditSol(sol)
}

const handleActiveSite = (e, site) => {
  const isActive = parseInt(site.active) === 1;
  e.preventDefault()
  e.stopPropagation()
  setConfirm({
      title: 'Confirmation',
      message: !isActive ?
          (`Voulez vous activez le site ${site.name} ?`)
          : (`Voulez vous désactivez le site ${site.name} ?`),
      callback: function (ev) {
          ev.preventDefault()
          ev.stopPropagation()
          ev.target.setAttribute('disabled', 'true')
          ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
          // active user
          dispatch(editSite({ id: site.id, active: isActive ? 0 : 1 })).then(() => {
              setConfirm(null)
              toast.success('Modification réussie.', { autoClose: 1500 })
          })
      }
  })
}

const handleDeleteSite = (e, site) => {
  e.preventDefault()
  e.stopPropagation()
  setConfirm({
      title: 'Confirmation',
      message: `Voulez vous supprimer le site ${site.name} ?`,
      callback: function (ev) {
          ev.preventDefault()
          ev.stopPropagation()
          ev.target.setAttribute('disabled', 'true')
          ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
          // active user
          dispatch(removeSite({ id: site.id })).then(() => {
              setConfirm(null)
              toast.success('Suppression réussie.', { autoClose: 1500 })
          })
      }
  })
}

const handleActiveSol = (e, sol) => {
  const isActive = parseInt(sol.active) === 1;
  e.preventDefault()
  e.stopPropagation()
  setConfirm({
      title: 'Confirmation',
      message: !isActive ?
          (`Voulez vous activez le site ${sol.name} ?`)
          : (`Voulez vous désactivez le site ${sol.name} ?`),
      callback: function (ev) {
          ev.preventDefault()
          ev.stopPropagation()
          ev.target.setAttribute('disabled', 'true')
          ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
          // active user
          dispatch(editAvailableSol({ id: sol.id, active: isActive ? 0 : 1 })).then(() => {
              setConfirm(null)
              toast.success('Modification réussie.', { autoClose: 1500 })
          })
      }
  })
}

const handleDeleteSol = (e, sol) => {
  e.preventDefault()
  e.stopPropagation()
  setConfirm({
      title: 'Confirmation',
      message: `Voulez vous supprimer le sol ${sol.name} ?`,
      callback: function (ev) {
          ev.preventDefault()
          ev.stopPropagation()
          ev.target.setAttribute('disabled', 'true')
          ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
          // active user
          dispatch(removeAvailableSol({ id: sol.id })).then(() => {
              setConfirm(null)
              toast.success('Suppression réussie.', { autoClose: 1500 })
          })
      }
  })
}

  return (
    <ProfileContainer>
      <div
        className="modal fade backdrop"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Profil
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Profil_Form />
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div style={{ width: "100%" }} className="DemandPage">
        <Tabs activeKey={activeTab} onSelect={handleTabChange} id="profileTabs">
          <Tab
            eventKey="pending"
            title={
              <span>
                <GiPositionMarker /> Sites de reception de sols contaminés
              </span>
            }
          >
            
            
            <>
              {onAddSite ? (
                <div class="d-flex scrollable__content">
                  <AddSiteForm
                    concentrations={concentrationData}
                    contaminantsList={contaminantData}
                    onBack={() => setOnAddSite(false)}
                  />
                </div>
              ) : ( 

                onEdit ? 
                  <div class="d-flex scrollable__content">
                    <EditSiteForm contaminantsList={contaminantData} site={onEdit} onBack={() => setOnEdit(false)} />
                  </div>
                :
                <section className="">
                  <div className="d-flex justify-content-end align-items-center">
              <button
                onClick={() => setOnAddSite(true)}
                className="btn border pageActionButton text-black-50 fw-bold text-uppercase"
              >
                
                <i className="fa fa-plus"></i> ajouter
              </button>
            </div>
                    <div className="container">
                    <div className="mt-5 d-flex justify-content-between w-100 mb-2 align-items-center">
                        <h5 className='fw-bold text-black-50 text-uppercase'>listes des sites</h5>
                        <input maxLength={10} placeholder="Recherchez un site" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search"></input>
                    </div>
                      {customTextFilter(userSites, q)?.length > 0 ? <DataTable className=''
                        columns={siteColumns}
                        data={customTextFilter(userSites, q)}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    /> : <div className="pt-5"><NoData /></div> }
                    </div>
                </section>
                

              )}
            </>
          </Tab>
          <Tab
            eventKey="inProgress"
            title={
              <span>
                <TbWashDrycleanOff /> Sols non contaminés
              </span>
            }
          >
             
            <div className="d-flex justify-content-end align-items-center">
              <button
                onClick={() => setOnAddSol(true)}
                className="btn border pageActionButton text-black-50 fw-bold text-uppercase"
              >
                 
                <i className="fa fa-plus"></i> ajouter
              </button>
            </div>
            <>
              {onAddSol ? (
                <div class="d-flex scrollable__content">
                  <AddAvailableSolForm
                    materialNames={materialNameData}
                    onBack={() => setOnAddSol(false)}
                  />
                </div>
              ) : (
                onEditSol ? 
                <div class="d-flex scrollable__content">
                  <EditAvailableSolForm sol={onEditSol} onBack={() => setOnEditSol(false)} />
                  </div>
                :
                <section className="">
                  <div className="container">
                  <div className="mt-5 d-flex justify-content-between w-100 mb-2 align-items-center">
                        <h5 className='fw-bold text-black-50 text-uppercase'>listes des sols</h5>
                        <input maxLength={10} placeholder="Recherchez un sol" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search"></input>
                    </div>
                      {customTextFilter(userAvailableSols, q)?.length > 0 ? <DataTable className=''
                        columns={SolsColumns}
                        data={customTextFilter(userAvailableSols, q)}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    /> : <div className="pt-5"><NoData /></div>}
                    </div>
                </section>
              )}
            </>
          </Tab>
        </Tabs>
      </div>

      

      {imageViewer &&
            <ImageViewer images={imageViewer} onClose={() => { setImageViewer(null) }} open={imageViewer != null} />
        }
        {confirm &&

            <Modal onClose={(e) => { setConfirm(null) }} open={confirm ?? false}
                title={<div className="text-uppercase" style={{ color: 'var(--primaryColor)' }}><i className="fas fa-exclamation-circle fa-1x"></i> {confirm.title}</div>}>
                {!confirm.onConfirm && <div className="d-flex flex-column justify-content-between p-3">
                    <div>
                        {confirm.message}
                    </div>
                    <div className="d-flex justify-content-end w-100 mt-3">
                        <button data-bs-toggle="modal" data-bs-target="#DefaultModal" style={{ background: 'var(--primaryColor) !important' }} className='downloadBtn btn btn-primary warning text-uppercase' onClick={(e) => confirm.callback(e)}>Confirmer</button>
                    </div>

                </div>}
            </Modal>
        }


      <div>

 
    </div>
      
    </ProfileContainer>
  );
};

export default ProfilePage;
