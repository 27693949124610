import React, { useRef, useEffect } from 'react';

function AddressAutocomplete({ onPlaceSelected, address, handleBlur, places, setPlaces, previous_address = null }) {

  const inputRef = useRef(null);

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, { types: ['address'] });

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace().formatted_address;
        setPlaces(place);
      });
    }
  }, [onPlaceSelected]);

  return (
    <input
      ref={inputRef}
      value={address}
      onChange={(e)=> setPlaces(e.target.value)}
      type="text"
      className="form-control"
      name="address"
      id='address'
      placeholder={previous_address ? previous_address : "adresse postale"}
      onBlur={handleBlur}
    />
  );
}

export default AddressAutocomplete;
