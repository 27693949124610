import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = (all=false) => {
    return axios.get(`booking${all?'?all=true':''}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const add = (item) => {
    return axios.post('booking', item, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const edit = (item) => {
    return axios.post(`/booking/edit/${item?.id ?? item.get('id')}`, item, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const remove = (item) => {
    return axios.delete(`/booking/${item?.id ?? item.get('id')}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}
const generatePaymentInvoice = (bookingId, paymentId) => {
    return axios.get(`/invoice/booking/${bookingId}/payment/${paymentId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    })
}

const BookingService = {
    getAll,
    add,
    edit,
    remove,
    generatePaymentInvoice
}

export default BookingService;
