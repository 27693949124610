import './../styles/NoData.css'

export default function NoData({
    message = 'Aucune donnée disponible',
    icon = <i className="fa fa-info-circle fa-5x"></i>
}) {

    return <div className="NoData d-flex justify-content-center align-items-center my-5 flex-column gap-2">
        {icon}
        <span className='mt-2'>{message}</span>
    </div>

}