import { isValidPhoneNumber } from "react-phone-number-input";

export const obfuscatePhoneNumber = (phone) => {
  if (!phone) {
    return null
  }
  return Array.from(phone).map((value, index, arr) => {
    if (index !== 0 && index <= (arr.length) / 3) {
      return "*"
    } else {
      return value
    }
  }).join("");
}

export const getOnlyOneName = (name) => {
  if (name) {
    if (name.includes(' ')) {
      return name.split(' ')[0]
    }
    return name
  }
}

export const formatsCardNumber = (e) => {
  let ele = e.target
  if (ele.value.length < 19) {
    ele.value = ele.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    return true;
  } else {

    return false;
  }
}

export const parseReadableDate = (date) => {
  let day = getDayweek(date),
    month = getMonth(date),
    dateDay = (new Date(date)).getDate(),
    year = (new Date(date)).getFullYear(),
    hour = (new Date(date)).getHours(),
    minutes = (new Date(date)).getMinutes()
  minutes = minutes < 10 ? `0${minutes}` : minutes
  hour = hour < 10 ? `0${hour}` : hour
  return dateDay + ' ' + month + ' ' +
    year + ' à ' +
    hour + 'h' + minutes
  // return day + ', ' + (new Date(date)).getDate() + ' ' + month + ' ' +
  //     (new Date(date)).getFullYear() + ' à ' +
  //     (new Date(date)).getHours() + 'h' + (new Date(date)).getMinutes()
}


export const formats = (e) => {
  let ele = e.target
  if (ele.value.length < 19) {
    ele.value = ele.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    return true;
  } else {
    return false;
  }
}

export const formatPrice = (price) => {
  //  return price
  return new Intl.NumberFormat('ca-CA', { style: 'currency', currency: 'cad' }).format(price)
}

export const formatsExpires = (e) => {
  let ele = e.target
  if (ele.value.length < 7) {
    ele.value = ele.value.replace(/\W/gi, '').replace(/(.{2})/g, '$1/');
    return true;
  } else {
    return false;
  }
}

export const numberValidation = (e) => {
  e.target.value = e.target.value.replace(/[^\d ]/g, '');
  return false;
}

export const getModelById = (models, id) => {
  let model = null;
  for (let index = 0; index < models.length; index++) {
    if (models[index].id == id) {
      //console.log(models[index]);
      model = models[index];
    }
  }
  return model;
};

export const getCartById = (models, id) => {
  let model = null;
  for (let index = 0; index < models.length; index++) {
    if (models[index].command_id == id) {
      model = models[index];
    }
  }

  return model;
};

export const getCartsById = (models, id) => {
  let carts = [];
  let i = 0;
  for (let index = 0; index < models.length; index++) {
    if (models[index].command_id == id) {
      carts[i] = models[index];
      i++;
    }
  }
  //console.log(carts);
  return carts;
};

export const getItemsById = (models, id) => {
  let items = [];
  let i = 0;
  for (let index = 0; index < models.length; index++) {
    if (models[index].items_id == id) {
      items[i] = models[index];
      i++;
    }
  }
  //console.log(items);
  return items;
};
export const getSubscriptionPrintRequest = (id, printRequests) => {
  let model = null;
  for (let index = 0; index < printRequests.length; index++) {
    if (printRequests[index].subscription_id == id) {
      model = printRequests[index];
    }
  }

  return model;
}

export const getSubscriptionPayment = (id, payments) => {
  let model = null;
  for (let index = 0; index < payments.length; index++) {
    if (payments[index].subscription_id == id) {
      model = payments[index];
    }
  }

  return model;
}

export function isPeriod(ratings, ratingPeriod) {
  let is_Period = false;
  if (Array.isArray(ratings) && ratings.length && Array.isArray(ratingPeriod) && ratingPeriod.length) {

    for (let index = 0; index < ratings.length; index++) {
      if (ratings[index].rating_periods_id == ratingPeriod[0].id) {
        is_Period = true;
      }
    }
  }
  console.log(is_Period);
  return is_Period;
}

export function isMeeting(ratings, meeting) {
  let is_meeting = false;
  if (Array.isArray(ratings) && ratings.length && Array.isArray(meeting) && meeting.length) {

    for (let index = 0; index < ratings.length; index++) {
      if (ratings[index].meetings_id == meeting[0].id) {
        is_meeting = true;
      }
    }
  }
  console.log(is_meeting);
  return is_meeting;
}



export const getDayweek = (date) => {
  let ladate = new Date(date)
  let tab_jour = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  return tab_jour[ladate.getDay()]
}

export const getMonth = (date) => {
  let ladate = new Date(date)
  let tab_mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  return tab_mois[ladate.getMonth()]
}


// Using fetch
export async function downloadImage(imageSrc, imageName) {
  console.log(imageSrc);
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = imageName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}


export const getHostName = () => {
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  return `${protocol}//${domain}${port ? ':' + port : ""}`
}

export const parsePaymentMode = (mode) => {
  switch (mode) {
    case 'cc':
      return 'carte de crédit'
    case 'om':
      return 'orange money'
    case 'momo':
      return 'mtn money'
    default:
      return null
  }

}


export const getModalVerificationMessage = (country, mail, phone) => {
  // console.log(country, mail, phone);
  if (country && !country.localeCompare('cameroon')) {
    if (mail) {
      return "Nous vous avons envoyé un code de vérification à 6 chiffres à l'adresse " + obfuscatePhoneNumber(mail);
    }
  } else {
    if (mail && phone) {
      return "Nous vous avons envoyé un code de vérification à 6 chiffres à " +
        obfuscatePhoneNumber(phone) + ' et à l\'adresse ' + obfuscatePhoneNumber(mail);
    } else if (mail) {
      return "Nous vous avons envoyé un code de vérification à 6 chiffres à l'adresse " + obfuscatePhoneNumber(mail);
    } else if (phone) {
      return "Nous vous avons envoyé un code de vérification à 6 chiffres au numéro " + obfuscatePhoneNumber(phone);
    } else {
      return 'Nous vous avons envoyé un code de vérification à 6 chiffre';
    }
  }
}

export function isEmail(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const authFormValidator = (name, value) => {
  if (name === 'last_name') {
    if (!/^[a-zA-Z]{3,}$/.test(value)) return 'incorrect last name';
  }
  if (name === 'first_name') {
    if (!/^([a-zA-Z]){3,}$/.test(value)) return 'incorrect first name';
  }
  if (name === 'email') {
    if (!isEmail(value)) return 'incorrect email';
  }
  if (name === 'city') {
    if (value.length < 2) return 'incorrect city';
  }
  if (name === 'address') {
    if (value.length < 4) return 'incorrect address';
  }
  if (name === 'phone') {
    if (!isValidPhoneNumber(value)) return 'incorrect phone';
  }
  if (name === 'password') {
    if (value.length < 6) return 'incorrect password';
  }
  if (name === 'id') { // email or phone number
    if (!isEmail(value) && !isValidPhoneNumber(value)) {
      return 'incorrect email or phone number';
    }
  }
  return null;
}

export const formatCreditCardNumber = (value) => {
  // Supprime tous les caractères non numériques
  let formattedValue = value.replace(/\D/g, '');
  // Ajoute un espace tous les quatre chiffres
  formattedValue = formattedValue.replace(/(\d{4})/g, '$1 ');
  // Supprime l'espace final s'il y en a un
  formattedValue = formattedValue.trim();
  // Limite la longueur à 19 caractères pour correspondre à la longueur maximale d'un numéro de carte de crédit
  formattedValue = formattedValue.slice(0, 19);
  return formattedValue;
};

export const formatCreditCardExpiry = (value) => {
  // Supprime tous les caractères non numériques
  let formattedValue = value.replace(/\D/g, '');
  // Ajoute un slash après le mois si nécessaire
  if (formattedValue.length > 2) {
    formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2);
  }
  // Limite la longueur à 5 caractères pour correspondre à la longueur maximale d'une date d'expiration de carte de crédit
  formattedValue = formattedValue.slice(0, 5);
  return formattedValue;
};

export const formatCreditCardCvc = (value) => {
  // Supprime tous les caractères non numériques
  let formattedValue = value.replace(/\D/g, '');
  // Limite la longueur à 4 caractères pour correspondre à la longueur maximale d'un code CVV de carte de crédit
  formattedValue = formattedValue.slice(0, 4);
  return formattedValue;
};