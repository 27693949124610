import React, { useEffect, useRef, useState } from "react";
import "./../styles/LocalisationInput.css";

import { AiOutlineSearch } from "react-icons/ai";

import { MdGpsFixed } from "react-icons/md";
import { toast } from "react-toastify";
import { DEFAULT_CANADA_POSITION } from "../../pages/DashboardPage";

const apiKey = "AIzaSyBwo0k-Ev4OQ02DkZZgTqGlIMjzuQz3IJM";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {

  if (!Array.isArray(place?.address_components)) {
    return null;
  }

  let formattedAddress = '';
  place.address_components.forEach((component) => {
    formattedAddress += component.long_name + ' ';
  });

  return formattedAddress;
};

function LocalisationInput({ onAddressChange }) {
  const searchInput = useRef(null);
  const [address, setAddress] = useState(null);

  // init gmap script
  /* const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }; */

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    console.log(place);
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current, { types: ["address"] }
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      onChangeAddress(autocomplete);
    }
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.placeholder = "Obtention de votre position...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        console.log(place);
        setAddress(place.formatted_address);
        searchInput.current.value = place.formatted_address;
      }).finally(() => {
        searchInput.current.placeholder = "Recherchez une localisation....";
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      }, function (error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
        };
    }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    });
    } else {
      toast.warn('Votre navigateur ne supporte pas la géolocalisation.')
  }
  };

  // load map script after mounted
  useEffect(() => {
    initAutocomplete()
  }, []);

  useEffect(() => {
    if (address) {
      onAddressChange(address);
    }
  }, [address])

  return (
    <div className="App">
      <div>
        <div className="search">
          <span>
            <AiOutlineSearch />
          </span>
          <input
            ref={searchInput}
            type="text"
            placeholder="Recherchez une localisation...."
          />
          <button onClick={()=> findMyLocation()}>
            <MdGpsFixed />
          </button>
        </div>
      </div>
    </div>
  );
}

export default LocalisationInput;
