import React from "react";
import './styles/MapCard.css'
import './styles/MapCardDemand.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatPrice } from "../../utils/utils";
import Selector from "../Selector";
import { getContaminantIdAndName } from "../../pages/DashboardPage";

const MapCardDemand = ({ item, contaminants, concentrations, selected = false, ...props }) => {
  console.log(item)

  var settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    center: true,
    arrows: false,
    /* autoplaySpeed: 3000, */
    cssEase: "linear"
  };

  const classname = "MapCard card m-2 site__card" + (selected ? ' selected' : '');

  return (
    <div onClick={(e) => { props.onClick(item) }} className={classname}  >
      <Slider {...settings} className="border__img-container" >
        {
          item.images.map(image => {
            return <img key={image} style={{ maxHeight: '12px' }} src={image} className="w-100" alt="..." />
          })
        }
      </Slider>
      <div className="mt-3">
        <div className=" d-flex flex-column justify-content-start">
          <h6 className="card-title text-clamp-1 pt-1">{item.name}</h6>

          {contaminants.length !== 6 && <h6 className='order__qty pt-1 d-flex justify-content-end'>{formatPrice(item.unit_price)}/tonnes</h6>}
        </div>

        <p className="site__type text-clamp-1"><span><i className="order__qty fa fa-gear"></i></span> {item.type}</p>
        <p className="site__position"><span><i className="fa fa-location-dot"></i></span> {item.address}, {item.postal_code}</p>
        <div className='w-100'>
          <Selector
            selectedId={[]}
            onChange={() => { }}
            values={getContaminantIdAndName([item], contaminants)}
            selectable={false}
            concentrations={concentrations}
            contaminants={contaminants} />
        </div>
      </div>
    </div>
  );
};



export default MapCardDemand;
