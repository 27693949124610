import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import MaterialNameService from "../../services/MaterialNameService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getMaterialName = createAsyncThunk(
    "MaterialName/all",
    async () => {
        const res = await MaterialNameService.getAll();
        return res.data;
    }
);


const materialNameSlice = createSlice({
    name: "MaterialName",
    initialState,
    extraReducers: {
        [getMaterialName.fulfilled]: (state, action) => {
            return action.payload
        }
    },
});

const { reducer } = materialNameSlice;
export default reducer;