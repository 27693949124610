import React from 'react'
import { useNavigate } from 'react-router-dom';
import { BsCheck2Circle } from "react-icons/bs";
import { motion } from 'framer-motion';

const OrderSuccessPage = () => {
    const navigate = useNavigate();

  return (
    <div style={{height: "100vh"}} className='search__site-form pb-4 gap-3 d-flex flex-column align-items-center justify-content-center '>

      <div className='d-flex text-center flex-column align-items-center justify-content-center' >
        <BsCheck2Circle style={{fontSize: '100px', color: "#64b882"}}/>

        <h2 className="text__smoke mt-2" style={{fontWeight: "500"}}>Payement effectué <br/> avec succès</h2>
      </div>

      <div className='mt-5 w-100 d-flex flex-wrap align-items-center justify-content-between' >
        <div className='col-lg-6 col-12 d-flex justify-content-sm-center justify-content-lg-end pe-lg-4 p-sm-0'>
          <motion.button
                  whileTap={{scale: 1.1}} type="button" className="py-3 wrap__btn save__oder btn search__btn rounded-pill col-lg-6 col-12"
                  style={{fontSize:"20px", background: "none", color: "#497894", border: "2px solid #497894"}}
                  onClick={()=> navigate("/")}
                >
          Revenir à l'accueil</motion.button>
        </div>

        <div className='col-lg-6 col-12 d-flex justify-content-sm-center justify-content-lg-start ps-lg-4 p-sm-0'>
          <motion.button
                  whileTap={{scale: 1.1}} type="button" className="py-3 wrap__btn btn search__btn rounded-pill col-lg-6 col-12"
                  style={{fontSize:"20px"}}
                >
          Télécharger le reçus</motion.button>
        </div>

      </div>
    </div>
  )
}

export default OrderSuccessPage