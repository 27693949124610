import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SiteService from "../../services/SiteService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSites = createAsyncThunk(
    "sites",
    async () => {
        const res = await SiteService.getAll();
        return res.data;
    }
);

export const getAllSites = createAsyncThunk(
    "sites/all",
    async (params) => {
        const res = await SiteService.getAllSites(params);
        return res.data;
    }
);


export const addSite = createAsyncThunk(
    "sites/add",
    async (item) => {
        const res = await SiteService.add(item);
        return res.data;
    }
);

export const editSite = createAsyncThunk(
    "sites/edit",
    async (item) => {
        const res = await SiteService.edit(item);
        return res.data;
    }
);


export const removeSite = createAsyncThunk(
    "sites/remove",
    async (item) => {
        const res = await SiteService.remove(item);
        return res.data;
    }
);


const siteSlice = createSlice({
    name: "site",
    initialState,
    extraReducers: {
        [getSites.fulfilled]: (state, action) => {
            return action.payload
        },
        [getAllSites.fulfilled]: (state, action) => {
            return action.payload
        },
        [addSite.fulfilled]: (state, action) => {
            if (state == null) {
                state = [action.payload.data];
            } else {
                state.push(action.payload.data)
            }
            return state
        },
        [editSite.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.map((i) => {
                if (i.id == item.id) return item
                return i
            })
        },
        [removeSite.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.filter((i) => {
                return i.id !== item.id
            })
        },
    },
});

const { reducer } = siteSlice;
export default reducer;