import './styles/FloatingSite.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MapCard from './MapCard';
import { useState, useEffect } from 'react';

function NextArrow(props) {
  const { className } = props;
  return (
    <div
      className={className}
    >
      <i className='fa fa-arrow-next' />
    </div>
  );
}

function PrevArrow(props) {
  const { className } = props;
  return (
    <div
      className={className}
    >
      <i className='fa fa-arrow-prev fa-5x' />
    </div>
  );
}


export default function FloatingSite({ sites, onSelect, selectedSite }) {

  const isClient = typeof window === "object";
  const [numberToShow, setNumberToShow] = useState(3);

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount


  useEffect(() => {
    //console.log(windowSize);
    if (windowSize.width <= 1250) {
      setNumberToShow(2)
    } else {
      setNumberToShow(3)
    }

  }, [windowSize])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberToShow,
    slidesToScroll: 1,
    /* centerMode: true, */
    cssEase: "linear",
    lazyLoad: true,
  };


  return <div className='floattingSlider d-flex d-lg-flex flex-column align-items-center justify-content-center position-absolute mx-auto col-12 mx-auto'>
    <Slider {...settings} className='slider__component col-12 col-lg-9 mx-auto'>
      {sites.map((item, index) => (
        <MapCard selected={selectedSite.includes(item.id)} onClick={onSelect} item={item} contaminants={[]} key={index} />
      ))}
    </Slider>
    
  </div>
}