import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSubscription_Plans } from '../redux/slices/subscription_planSlice';
import './../styles/SubscriptionPage.css'
import { motion } from 'framer-motion';
import CircularLoader from "../components/loader/CircularLoader";
import { formatPrice } from "../utils/utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionPaymentForm from "../components/form/payment/SubscriptionPaymentForm";
import { getCurrentUser } from "../redux/slices/userSlice";



const SubscriptionPage = () => {

  const subscription_plans = useSelector((state) => state.subscription_plan);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const userData = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);


  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  useEffect(() => {
    if (!subscription_plans) dispatch(getSubscription_Plans());

  }, [subscription_plans]);

  useEffect(() => {
    if (!userData) {
      dispatch(getCurrentUser()).unwrap().then((data) => {
        if (data) {
          if (data.subscription_plan == null) {
            navigate('/login');
          }
        } else {
          navigate('/');
        }
      }).catch(() => navigate('/login'));
    } else {
      if (userData.subscription_plan !== null) {
        navigate('/');
      }
    }
  }, [])

  const handleSubscribe = (plan) => {
    setSelectedPlan(plan);
  }


  return <div className='p-0 w-100' style={{ position: "relative" }}>

    <header style={{ position: 'fixed', height: 60, top: 0, left: 0, right: 0, zIndex: 10 }} className="bg__smoke d-flex align-item-center justify-content-center w-100 shadow-sm">
      <img alt="Clicsol" style={{ width: 145 }} className=' pt-3' src='./logo.png' />
    </header>

    <div className='d-flex align-items-center justify-content-center text-center mt-5'>
      <h6 className='subscription__title mt-5'>
        choisissez le bon plan pour vous et commencez à utiliser la plateforme ClicSol
      </h6>
    </div>
    {!selectedPlan ? <div className='p-0 m-0 mt-5 w-100 d-flex justify-content-center flex-column gap-2 align-items-center'>

      {subscription_plans ? <>
        <div id="pricing" className='mt-4'>
          <div class="single-card">
            <div class="wrapper">
              <h3 className="text-capitalize">{subscription_plans[0]?.name}</h3>
              <h1>{formatPrice(subscription_plans[0]?.price)}</h1>
              <ul>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li class="disable">
                  <i class="fas fa-times"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
                <li class="disable">
                  <i class="fas fa-times"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li class="disable">
                  <i class="fas fa-times"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
              </ul>
              <motion.button onClick={(e) => { handleSubscribe(subscription_plans[0]) }} whileTap={{ scale: 1.1 }}>Souscrire</motion.button>
            </div>
          </div>

          <div class="single-card">
            <div class="wrapper popular">
              <h3 className="text-capitalize">{subscription_plans[1]?.name}</h3>
              <h1>{formatPrice(subscription_plans[1]?.price)}</h1>
              <ul>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
                <li class="disable">
                  <i class="fas fa-times"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li class="disable">
                  <i class="fas fa-times"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
              </ul>
              <motion.button onClick={(e) => { handleSubscribe(subscription_plans[1]) }} whileTap={{ scale: 1.1 }}>Souscrire</motion.button>
            </div>
          </div>

          <div class="single-card">
            <div class="wrapper">
              <h3 className="text-capitalize">{subscription_plans[2]?.name}</h3>
              <h1>{formatPrice(subscription_plans[2]?.price)}</h1>
              <ul>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>Lorem ipsum dolor sit</span>
                </li>
              </ul>
              <motion.button onClick={(e) => { handleSubscribe(subscription_plans[2]) }} whileTap={{ scale: 1.1 }}>Souscrire</motion.button>
            </div>
          </div>
        </div>
      </>
        : <CircularLoader />
      }

    </div>

      :

      <div style={{ marginTop: 80 }} className='container process__order'>
        <div className='mt-5 w-100 d-flex align-items-center justify-content-start gap-4'>
          <div style={{ cursor: "pointer" }} onClick={() => setSelectedPlan(null)}>
            <i style={{ fontSize: "40px" }} className="fa-solid fa-arrow-left"></i>
          </div>
          <h3 className='m-0'>Payement de l'offre <span style={{ color: "#36a35d" }} className="text-uppercase">{selectedPlan?.name}</span>  </h3>
        </div>

        <div className="">
          {stripePromise ? <Elements stripe={stripePromise}>
            <SubscriptionPaymentForm
              amount={selectedPlan.price}
              dataForPayment={{ subscription_id: selectedPlan.id }}
            />
          </Elements> : <CircularLoader />}
        </div>
      </div>
    }

  </div>
}

export default SubscriptionPage;