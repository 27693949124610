import React,{useEffect, useRef, useState} from 'react'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import LocalisationInput from './LocalisationInput';
import { toast } from 'react-toastify';


const SearchSolForm = ({handleSearch}) => {
  const navigate = useNavigate();
  const btnRef = useRef()

  const [address, setAddress] = useState(null);
  const [ray, setRay] = useState(null);

  const handleSearchSol = () => {
    const solDetails = {
      address,
      ray,
    }

    let isvalid = true;
    let missingFields = "";

    if (!address) {
      isvalid = false;
      missingFields += "adresse, ";
    }
    if (!ray) {
      isvalid = false;
      missingFields += "rayon géographique, ";
    }

    if (isvalid) {
      handleSearch(solDetails);

      btnRef.current.click()
    } else {
      missingFields = missingFields.slice(0, -2); // supprime la dernière virgule et l'espace
      const errorMessage = `Les champs suivants sont manquants ou invalides : ${missingFields}`;
      toast.warning(errorMessage);
    }

  }



  useEffect(()=>{
    if (ray && ray < 0) {
      setRay(0)
    }
  },[ray])

  return (

      <><button className='d-none' ref={btnRef} data-bs-toggle="modal" data-bs-target="#searchSolsModal"></button>
      <div className="modal fade overflow-hidden" id="searchSolsModal"
      tabIndex="-1"
      aria-hidden="true">

      <div className="w-100 d-flex align-items-center justify-content-center pt-5">
        <motion.div  style={{ cursor: "pointer", fontSize: "40px", width: "60px", height: "60px", background: "#fff", color: "#3da663" }}
          className="mb-lg-0 mb-5 p-1 rounded-circle d-flex justify-content-center align-items-center"
          data-bs-toggle="modal" data-bs-target="#searchSolsModal"
          whileTap={{ scale: 1.1 }}
        >
          <span aria-hidden="true">&times;</span>
        </motion.div>

      </div>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content position-relative pt-1  log_ca-form" style={{ top: "-100px" }}>

          <div className="modal-body p-4 px-5 pb-0">
            <h4 className="modal-title text__smoke" id="exampleModalLabel">Recherche de sols</h4>

            <div style={{}} className='mt-4 pb-4 gap-3 d-flex flex-column align-items-center '>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span className='mb-4'>Adresse</span>

                <LocalisationInput onAddressChange={setAddress} />
              </div>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span>rayon géographique</span>

                <div className='p-2 mt-3 border__rounded border d-flex flex-row align-items-center justify-content-between'>
                  <input onChange={e => setRay(e.target.value)} value={ray} style={{ width: "60%", fontSize: 12.5 }} type="number" min={0} className='border-0 bg__smoke'
                    placeholder='veuillez renseigner la distance' />
                  <div className="bg__smoke-variant rounded">
                    Km
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="modal-footer d-flex align-items-center justify-content-center">
            <motion.button whileTap={{ scale: 1.1 }} type="button" className="btn search__btn rounded-pill"
              onClick={() => handleSearchSol()}
            >Rechercher le sol</motion.button>
          </div>




        </div>
      </div>
    </div></>
  )
}

export default SearchSolForm