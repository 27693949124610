import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {Subscription_PlanService} from "../../services/Subscription_PlanService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSubscription_Plans = createAsyncThunk(
    "Subscription_Plans/all",
    async () => {
        const res = await Subscription_PlanService.getAll();
        return res.data;
    }
);



const subscription_planSlice = createSlice({
    name: "Subscription_Plans",
    initialState,
    extraReducers: {
        [getSubscription_Plans.fulfilled]: (state, action) => {
            return action.payload
        }
    },
});

const { reducer } = subscription_planSlice;
export default reducer;