import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ContaminantService from "../../services/ContaminantService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getContaminants = createAsyncThunk(
    "contaminant/all",
    async () => {
        const res = await ContaminantService.getAll();
        return res.data;
    }
);



const contaminantSlice = createSlice({
    name: "contaminant",
    initialState,
    extraReducers: {
        [getContaminants.fulfilled]: (state, action) => {
            return action.payload
        }
    },
});

const { reducer } = contaminantSlice;
export default reducer;