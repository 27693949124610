import React from 'react'
import { useParams } from "react-router-dom";
import InformationContainer from '../components/ProfileContainer';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './../styles/OrderSitePage.css'

import Visa from "../../src/assets/images/visa-icon.png";
import MasterCard from "../../src/assets/images/Mastercard-icon.png";

const OrderSitePage = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const className = "bg__smoke-variant rounded";

  return (
    <InformationContainer>



      <div style={{}} className='container process__order'>
      <div className='mt-5 w-100 d-flex align-items-center justify-content-start gap-5'>
        <div style={{ cursor: "pointer"}} onClick={()=>navigate("/information/confirmOrder/1")}>
          <i style={{fontSize: "40px"}} className="fa-solid fa-arrow-left"></i>
        </div>
        <h3 className='m-0'>Payement du site</h3>
      </div>

      <div className='responsive__container'>

        <div style={{height: "60vh"}} className='col-12 container__wrapper d-flex flex-wrap'>

        

        <div className='col-lg-9 col-sm-12 first__bloc mt-5 d-flex flex-column'>

          <h4>Informations de la carte de crédit</h4>

          <div className='col-8 card__infos d-flex flex-column mt-4 bg__smoke rounded__container shadow-sm'>
            <div className='ps-5 bg__smoke w-100 d-flex flex-row align-items-center justify-content-between'>
                  <input style={{ width: "60%" }} className='border-0 bg__smoke py-4' placeholder='Numéro de la carte' />
                  <div className="">
                    <img className='me-2' src={Visa} alt='' />
                    <img className='me-2' src={MasterCard} alt='' />
                  </div>
            </div>

            <div className='w-100 d-flex border-top'>
              <div style={{width: "50%"}} className='border-end ps-5'>
                <input style={{}} className='w-100 border-0 bg__smoke py-4' placeholder="Date d'expiration" />
              </div>
              <div style={{width: "50%"}} className='ps-5'>
                <input style={{}} className='w-100 border-0 bg__smoke py-4' placeholder="Code de sécurité" />
              </div>
            </div>

          </div>
    
          <h6 style={{ fontSize: "12px" }} className='mt-3'>Payement sécurisé par <a href='https://stripe.com/fr-us' style={{color: "#36a35d", fontWeight: "600"}} className=''>Stripe</a></h6>

        </div>

        <div className='col-lg-3 col-sm-12 mt-5 second__bloc  d-flex'>

              <div style={{ height: "85px",cursor: "pointer", background: "#36a35d", color: "#fff", lineHeight: "30px" }} className="shadow-sm rounded_container-price p-3 py-2 border">
                    <span style={{ fontSize: "14px"}}>Prix</span> <br />
                    <h4>1000$/tonnes</h4>
                  </div>
            </div>
        </div>


          <div style={{height: "10vh"}} className="mt-3 btn__container p-4 border-top d-flex align-items-center pe-5">
          <motion.button onClick={()=>navigate("/profil/order/success")} whileTap={{ scale: 1.1 }} type="button" className="order__btn rounded-pill me-5"
          >Payer 1000$</motion.button>
          </div>
      </div>

      
      </div>

      
    </InformationContainer>
  )
}

export default OrderSitePage