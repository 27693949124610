import { useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useLanguage from "../../hook/useLanguage";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import { UserService } from "../../services/UserService";
import Alert from "../Alert";
import "./../styles/LoginForm.css";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";

const LoginForm = () => {
  const [passwordVisibility, handleTogglePasswordVisibility] =
    usePasswordVisibility(true);
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();
  const [message, setMessage] = useState(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

    const btnRef = useRef()


  function validate(values) {
    const errors = {};
    if (!values.email) {
      errors.email = "Requis";
    }

    if (!values.password) {
      errors.password = "Requis";
    }

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) && values.email) {
        errors.email = "email non valide!"
     
    }

    if (values.password.length < 6 && values.password) {
        errors.password = "le mot de passe doit dépasser 6 caractères";
    }

    return errors;
  }
  

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values, 
    errors,
    setErrors,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {

      __handleSubmit()
    },
  });


  const __handleSubmit = async (e) => {
    setError(null)

    setLoading(true);

    UserService.signin(values)
      .then((json) => {
        if (json.data.errors) {

            setError(json.data.errors[0]);
          
        } else {
          setMessage(t("Connexion réussie redirection..."));
          new Promise((resolve, reject) => {
            localStorage.clear();
            localStorage.setItem("authToken", json.data.token);
            localStorage.setItem("authTokenDate", new Date());
            resolve(localStorage.getItem("authToken"));
          }).then(() => {
            navigate("/");
            toast.success("Connexion réussie");
          }); // navigate to admin account
        }
      })
      .catch((err) => {
        
        const data = err.response.data;

        if (data.errors) {

          if (data.errors?.email) {
            setError(data.errors.email);
          }else if(data.errors?.password){
            setError(data.errors.password);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div className="LoginForm shadow-lg rounded col-12">
      <form className="p-2 m-2 py-4" onSubmit={handleSubmit}>
        <h3 className="mb-4">Connexion</h3>
        <Alert message={message} type={"success"} />

        <Alert message={error} type={"danger"} />

        <div className="input-group mb-3">
          <input
            required
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            className="form-control"
            name="email"
            placeholder="Adresse mail"
          />
          <span className="input-group-text" id="togglePasswordVisibility">
            <i className="fa fa-envelope"></i>
          </span>
        </div>
        {touched.email && errors.email && (
          <span className="form-error mb-3">
            <i style={{ fontSize: ".8em", color: '#f5c2c7' }} className="fa fa-info-circle"></i>{" "}
            {t(errors.email)}
          </span>
        )}
        <div className="input-group mb-3">
          <input
            required
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type={!passwordVisibility ? "text" : "password"}
            className="form-control"
            name="password"
            placeholder={"mot de passe"}
          />
          <span className="input-group-text" id="togglePasswordVisibility">
            <i
              onClick={handleTogglePasswordVisibility}
              className="fa fa-eye"
            ></i>
          </span>
        </div>
        {touched.password && errors.password && (
          <span className="form-error mb-3">
            <i style={{ fontSize: ".8em", color: '#f5c2c7' }} className="fa fa-info-circle"></i>{" "}
            {t(errors.password)}
          </span>
        )}
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-around justify-content-lg-between gap-3 gap-lg-5 w-100">
          <div className="d-inline-flex">
            <div className="gap-2 d-flex justify-content-center">
              <input type="checkbox" id="remember" value="remember" />
            </div>
            <label htmlFor="remember" className="ms-1">
              Rester connecté
            </label>
          </div>
          <NavLink className="ms-auto" to="/forgot-password">
            Mot de passe oublié ?
          </NavLink>
        </div>
        <button
        ref={btnRef}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
          disabled= {loading}
        >

        {!loading ?
          <span className="">Connexion</span>
          :
          <><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div></>
        }
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
