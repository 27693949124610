import React,{useState, useEffect,useRef} from "react";
import './styles/MapCard.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatPrice } from "../../utils/utils";

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getDistance from 'geolib/es/getDistance';
import useGeocodeAddress from "../../hook/useGeocodeAddress";

const SolCard = ({ item,material_names, position, ...props }) => {


  var settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    center: true,
    arrows: false,
    /* autoplaySpeed: 3000, */
    cssEase: "linear"
  };
  

  const customFilter = (data, search) => {
    
    if (data) {
      let result = data?.filter((val) => (
        val.id == search
      ));
  
    return result[0]?.name
    }

}


  const classname = "MapCard card m-2 site__card shadow-sm";

  return (
    <div onClick={(e) => { props.onClick(item) }} data-bs-toggle="modal" data-bs-target="#detailsSolModal" className={classname}  >
      <Slider {...settings} className="border__img-container" >
        {
          item.images.map(image => {
            return <img key={image} style={{ maxHeight: '12px' }} src={image} className="card-img-top w-100" alt="..." />
          })
        }
      </Slider>
      <div className="card-body">
        <div className=" d-flex mb-1 flex-column justify-content-start">
          <h6 className="card-title text-clamp-1 pt-1">{item.name}</h6>

          <div style={{overflowX: "scroll"}} className='d-flex gap-3 pb-1'>

            {JSON.parse(item?.material_info)?.map((item, index) => (
                  <div key={index} className='border contaminant text-center rounded p-1 px-2' style={{fontSize: "9px"}}>
                    {customFilter(material_names,item.materialName)} {item.quantity} tonnes {formatPrice(item.price)} / t
                  </div>
            ))}
          {/* <h6 className='order__qty pt-1 d-flex justify-content-end'>{formatPrice(item.unit_price)}/tonnes</h6> */}
          </div>
        </div>

        <p className="site__position"><span><i className="fa fa-location-dot"></i></span> {item.address}, {item.postal_code}</p>
        <p className="site__distance"><span><i className="fa fa-diamond-turn-right"></i></span> A <span className="fw-bold">{item.distance && Number(item.distance).toFixed(2)} km</span> de votre position</p>

      </div>
    </div>
  );
};



export default SolCard;
