import { motion } from 'framer-motion';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { useCallback, useEffect, useMemo, useState } from 'react';

const Selector = ({ selectedId = [], values, onChange, selectable = true }) => {
  const [selected, setSelected] = useState(selectedId);

  const handleChange = useCallback(
    (data) => {
      if (onChange) onChange(data);
    },
    [onChange]
  );

  const memoizedValues = useMemo(() => values, [values]);

  const handleSelect = (id) => {
    if(!selectable) return;
    if (selected.includes(id)) {
      setSelected(selected.filter((itemId) => itemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  useEffect(() => {
    handleChange(selected);
  }, [selected, handleChange]);

  return (
    <div className='d-flex ContaminantSelect gap-3'>
      {memoizedValues.map(({ id, name }) => (
        <Tippy
          key={id}
          animation='fade'
          arrow={false}
          interactive={true}
          placement={'bottom'}
          appendTo='parent'
          trigger={'click'}
        >
          <motion.div
            whileTap={{ scale: 0.97 }}
            className={`ContaminantSelect__item ${selected.indexOf(id) !== -1 ? 'show' : ''
              }`}
            onClick={() => handleSelect(id)}
          >
            {name}
          </motion.div>
        </Tippy>
      ))}
    </div>
  );
};

export default Selector;
