import axios from "axios"

const getAll = () => {
    return axios.get(`subscription`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const subscribe = (item) => {
    return axios.post('test-payment', item, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


export const SubscriptionService = {
    subscribe,getAll
}

