import React,{useState,useRef} from 'react'
import { motion } from 'framer-motion';
import { GoSettings } from "react-icons/go";
import { MdArrowBackIosNew } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { BsCheck2Circle } from "react-icons/bs";
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addBooking } from '../../redux/slices/bookingSlice';
import { useDispatch } from 'react-redux';


const LogForm = ({siteDetails,selectedSitesIds, setSelectedSitesIds}) => {
  const formRef = useRef()
  const inputRef = useRef()
  const btnRef = useRef()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [description, setDescription] = useState("");

  const [fileList, setFileList] = useState(null);

  const [loading, setLoading] = useState(false);

  const files = fileList ? [...fileList] : [];

  const handleFileChange = (e) => {
    const temp = files
    temp.push(...e.target.files)
    setFileList(temp);
  };
  
  const handleClick = () => {
    if (files.length != 0) {
      setLoading(true)

      for (let index = 0; index < selectedSitesIds.length; index++) {
        
        const requestFiles = new FormData(formRef.current)
  
        if (description.length > 0) {
          requestFiles.append("description", description)
        }
  
        requestFiles.append("site_id", JSON.stringify(selectedSitesIds[index]))
        requestFiles.append("quantity", siteDetails.qty)
        requestFiles.append("contaminants", JSON.stringify(siteDetails.contaminants))
        requestFiles.append("physical_state", (siteDetails.state).toLowerCase())
        requestFiles.append("type_of_soil", JSON.stringify(siteDetails.type))

        dispatch(addBooking(requestFiles))
        .then((json) => {
          console.log(json);
          if (json.payload.errors) {
            toast.error(json.payload.message);
            console.log(json.payload.message);
          }else{
            setSelectedSitesIds([]);
            btnRef.current.click()
            toast.success('Commande enregistrée!');
          }
          setLoading(false)
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            toast.error(
              "Des erreurs ont été rencontrées"
            );
          }
        })
        .finally(() => {
          setLoading(false)
        });
      }




    }else{
      toast.warning("veuillez importer les fichiers");
    }
    
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const temp = files
    temp.push(...event.dataTransfer.files)

    setFileList(temp) 
  };

  const handleDelete = (file) => {
    const result = files.filter(
      (item) => item.name !== file.name
    );

    setFileList(result)
  }

  


  return (
  <><div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content pt-1  log_ca-form">

          <div style={{ padding: "25px" }} className='pt-4 px-5 d-flex flex-row align-items-center justify-content-between'>
            <div className='responsive__title'>
              <h6 className="modal-title text__smoke" id="exampleModalLabel">Joindre le log, le CA et les autres <br /> fichiers</h6>
              {/* <h6 className="modal-title fs-6" id="exampleModalLabel" style={{color: "#64b882"}}>Etape 1/3</h6> */}

            </div>

            <div data-bs-toggle="modal" data-bs-target="#exampleModal3" style={{ cursor: "pointer", fontSize: "25px", width: "50px", height: "50px", background: "#d5d5d5" }} className="p-1 search__icon rounded-circle d-flex justify-content-center align-items-center">
              <GoSettings />
            </div>
          </div>


          <div onClick={(e) => inputRef.current.click()} style={{ padding: "25px" }}
            className='log__btn mx-5 rounded pt-4 px-4 d-flex flex-row align-items-center justify-content-between'
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className='responsive__title'>
              <p className="modal-title text__smoke" style={{ fontSize: "13px" }}>
                Cliquez pour importer les fichiers <br /> ou glissez déposer </p>
            </div>

            <i style={{ color: "#64b882" }} className="fas fs-1 fa-file-invoice"></i>

            <form ref={formRef}> 
              <input name="log[]" ref={inputRef} type="file" accept="application/pdf" onChange={handleFileChange} multiple className='d-none' />
            </form>
          </div>

          {files.length > 0 && <div className='px-2 pt-3 d-flex align-items-center justify-content-center'>
            <ul className='p-0' style={{ fontSize: "13px" }}>
              {files.map((file, i) => (
                <div key={i} className='d-flex gap-2'>
                  <li>
                    {file.name} - {file.type}
                  </li>
                  <motion.div onClick={() => handleDelete(file)} whileHover={{ scale: 1.5 }} style={{ cursor: "pointer" }}>
                    <IoMdClose />
                  </motion.div>
                </div>
              ))}
            </ul>
          </div>}


          <div style={{ padding: "25px", background: "#f6f6f6" }} className='mx-5 mt-3 rounded py-3 px-4 d-flex flex-column'>

            <h5 className='text__smoke'>Description</h5>

            <div className='mt-4 pb-1 border-bottom'>
              <input className='border-0 w-100' value={description} type="text" onChange={(e) => setDescription(e.target.value)}
                style={{ fontSize: "11px", background: "#f6f6f6" }}
                placeholder='veuillez renseigner toute autre information supplémentaire' />
            </div>

          </div>


          <div className="modal-footer py-4 border-0 d-flex align-items-center justify-content-around">
            <div data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{ cursor: "pointer", fontSize: "25px", width: "50px", height: "50px", background: "#d5d5d5" }} className="p-1 search__icon rounded-circle d-flex justify-content-center align-items-center">
              <MdArrowBackIosNew />
            </div>

            <div className="modal-footer p-0 border-0 d-flex align-items-center justify-content-center">
              <motion.button
                whileTap={{ scale: 1.1 }} type="button" className="btn buy__btn rounded-pill"
                onClick={()=>handleClick()}
              >
                Commander le site 
              </motion.button>
              <button className='d-none' ref={btnRef} data-bs-toggle="modal" data-bs-target="#exampleModal4" ></button>
            </div>
          </div>
          {loading ? <div style={{zIndex: 10000, top: "50%", left: "44%"}} className="position-absolute CircularLoader"></div> : null}

        </div>
      </div>
    </div>

    
    
    
    <div className="modal fade" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content py-4 px-lg-5 px-md-5 px-sm-0 log_ca-form">

            <div className="border-0 d-flex flex-column align-items-center justify-content-center">

              <div className='' style={{ fontSize: '80px', color: "#64b882" }}>
                <BsCheck2Circle />
              </div>

              <h3 className=''>Commande enregistrée</h3>

              <p style={{ fontSize: "13px", fontWeight: "500" }} className="mt-2 text-center">
                Votre commande a bien été enregistrée <br /> elle sera analysée par un admininstrateur et vous <br /> serez notifié par email
              </p>

              <div className='w-100'>
                <div className=" mx-4 mx-lg-0 mx-md-0 d-flex align-items-center justify-content-center gap-2 py-1 mt-2 rounded-pill bg-dark text-light">
                  <div style={{ color: "#fff" }} className=''>
                    <i style={{ background: "black", borderRadius: "100%" }} className="fa fa-exclamation-circle" aria-hidden="true"></i>
                  </div>

                  <span style={{ fontSize: "10.5px", fontWeight: "500" }}>
                    Vous pouvez suivre le status depuis votre profil sous l'onglet attente
                  </span>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 border-0 d-flex align-items-center justify-content-center">
              <motion.button style={{ marginTop: "40px" }} onClick={e => {
                window.location.href = '/my-requests';
              }} data-bs-toggle="modal" whileTap={{ scale: 1.1 }} type="button"
                className="btn buy__btn rounded-pill px-5">
                Consulter mon profile
              </motion.button>
            </div>



          </div>
        </div>
      </div></>
  )
}

export default LogForm