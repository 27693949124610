import axios from "axios"

const getAll = () => {
    return axios.get(`subscription_plans`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


export const Subscription_PlanService = {
    getAll
}

