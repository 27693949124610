import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SiteService from "../../services/SiteService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSitesNear = createAsyncThunk(
    "search/all",
    async (params) => {
        const res = await SiteService.getAllNear(params);
        return res.data;
    }
);


const searchSlice = createSlice({
    name: "search",
    initialState,
    extraReducers: {
        [getSitesNear.fulfilled]: (state, action) => {
            return action.payload
        },
    },
});

const { reducer } = searchSlice;
export default reducer;