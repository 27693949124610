import { Modal, Tab, Tabs } from 'react-bootstrap';
import { FaClock, FaSpinner, FaTimes, FaCheck, FaDownload } from 'react-icons/fa';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../components/NoData';
import { getBookings } from '../redux/slices/bookingSlice';
import { formatPrice } from '../utils/utils';
import moment from 'moment/moment';
import MapCardDemand from '../components/map/MapCardDemand';
import { getContaminants } from '../redux/slices/contaminantSlice';
import { getConcentrations } from '../redux/slices/concentrationSlice';
import CircularLoader from '../components/loader/CircularLoader';
import "./../styles/InformationPage.css";
import { loadStripe } from '@stripe/stripe-js'; import { Elements } from "@stripe/react-stripe-js";
import BookingPaymentForm from '../components/form/payment/BookingPaymentForm';
import { toast } from 'react-toastify';
import BookingService from '../services/BookingService';

const InformationPage = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [activePaymentTab, setActivePaymentTab] = useState('payOwn');
  const [q, setQ] = useState(null);
  const bookingData = useSelector((state) => state.booking);
  const paymentData = useSelector((state) => state.payment);
  const concentrationData = useSelector((state) => state.concentration);
  const contaminantsData = useSelector((state) => state.contaminant);

  const [price, setPrice] = useState(null);
  const tabPaneref = useRef(null);
  const [tabPaneWidth, setTabPaneWidth] = useState('auto');

  const [selectedBooking, setSelectedBooking] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const bookingColumns = [
    {
      name: 'Date',
      selector: (row) => {
        return moment(new Date(row.created_at)).format('DD MMM YYYY')
      }
    },
    {
      name: 'Payement',
      selector: (row) => {
        return <div style={{ background: '#ff000080' }} className='border p-1 rounded'>{formatPrice(row.total)}/{row.percent}%</div>
      }
    },
    {
      name: 'Site',
      selector: (row) => {
        return <div>
          <div title={row?.site?.name}>{row?.site?.name}</div>
        </div>
      }
    },
    {
      name: 'Quantite',
      selector: (row) => {
        return `${row.quantity} tonnes`
      }
    },
    {
      name: 'Prix Unitaire',
      selector: (row) => {
        return formatPrice(row.price)
      },
      sortable: true,
    },

    {
      name: 'Actions',
      selector: (row) => {
        return <div className="d-flex justify-content-around w-100">
          <button title="voir plus de details" onClick={(e) => handleOpenDetail(row)} style={{ color: "#22aa22", borderColor: "#22aa22 !important", backgroundColor: 'transparent !important' }} className="mx-1 actionBtn">detail <i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
        </div>

      }
    }

  ];
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setSelectedBooking(null)
    setActiveTab(tab);
  };

  const handlePaymentTabChange = (tab) => {
    if (tab === 'payOwn') {
      setPrice('')
    } else {
      setPrice(selectedBooking.price - selectedBooking.total)
    }
    setActivePaymentTab(tab);
  };

  const handleCloseDetail = () => {
    setSelectedBooking(null)
  }

  const handleOpenDetail = (booking) => {
    setSelectedBooking(booking)
  }

  const __customTextFilter = (data, tab, search) => {
    if (search === null || search === undefined) {
      return data
    }
    search = search.toLowerCase()
    return data.filter((val, key) => {

      return val?.name && (val.name.toLowerCase().includes(search) || val.address.toLowerCase().includes(search) || val.city.toLowerCase().includes(search))
    })
  }

  const customTextFilter = (data, tab, search) => {
    const bookings = __customTextFilter(data, tab, search)
    return bookings?.filter((booking) => {
      return booking.status === tab;
    })
  }

  const [onCancel, setOnCancel] = useState(false);
  const [onPay, setOnPay] = useState(false);


  const handlePayment = () => {
    setOnPay(true)
  }

  const handleOpenInvoice = (booking, payment) => {
    toast.dismiss()
    toast.loading('Génération de la facture en cours...');
    BookingService.generatePaymentInvoice(booking.id, payment.id)
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(pdfBlob);

        // Open the PDF in a new browser window
        window.open(url, '_blank');

        // Release the object URL when no longer needed
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        toast.dismiss()
      });;
  }

  useEffect(() => {
    if (bookingData === null) dispatch(getBookings())
  }, [])

  useEffect(() => {
    if (!contaminantsData) dispatch(getContaminants());
    if (concentrationData === null) dispatch(getConcentrations())
  }, [concentrationData, contaminantsData]);

  useEffect(() => {

  }, [selectedBooking])

  useEffect(() => {
    if (tabPaneref && tabPaneref.current) {
      setTabPaneWidth(tabPaneref.current.offsetHeight);
    }
  }, [])


  if (!concentrationData || !contaminantsData) {
    return <CircularLoader />
  }



  return <div>
    <div className='p-3 InfoPage'>

      <div className='InfoPage__title d-flex justify-content-between align-items-center'>
        <h4 className='fw-bold text-black-90 text-uppercase'>Suivez vos demandes de dépots de sols</h4>
      </div>
      <div>
        <Tabs className="demandeTabs d-flex justify-content-around my-4" activeKey={activeTab} onSelect={handleTabChange} id="demandeTabs">
          <Tab eventKey="pending" title={<div className='d-flex justify-content-center align-items-center gap-1'><FaClock /> <span>Demandes en attente</span> <span class='badge badge-primary'>{customTextFilter(bookingData, 'pending', null)?.length}</span></div>}>
          </Tab>

          <Tab eventKey="confirmed" title={<div className='d-flex justify-content-center align-items-center gap-1'><FaSpinner /> <span>Demandes en cours</span> <span class='badge badge-primary'>{customTextFilter(bookingData, 'confirmed', null)?.length}</span></div>}>
          </Tab>

          <Tab eventKey="rejected" title={<div className='d-flex justify-content-center align-items-center gap-1'><FaTimes /> <span>Demandes rejetées</span> <span class='badge badge-primary'>{customTextFilter(bookingData, 'rejected', null)?.length}</span></div>}>
          </Tab>

          <Tab eventKey="completed" title={<div className='d-flex justify-content-center align-items-center gap-1'><FaCheck /> <span>Demandes terminées</span> <span class='badge badge-primary'>{customTextFilter(bookingData, 'completed', null)?.length}</span></div>}>
          </Tab>
        </Tabs>
        <div>
          <div className='d-flex flex-column'>
            {selectedBooking &&

              <div style={{ minHeight: '25vh' }} className='p-2 flex-column bookingDetails rounded border'>
                <div className='d-flex flex-column p-2  d-flex justify-content-between align-items-start'>
                  <button onClick={(e) => { handleCloseDetail() }} title='fermer' className='bookingDetails__closeBtn text-muted'>X</button>
                  <div style={{ height: `${tabPaneWidth}px` }} className='d-flex justify-content-between align-items-start w-100 row'>
                    <div ref={tabPaneref} className='col d-flex flex-column align-items-center border-end h-100'>
                      <div className='text-uppercase border-bottom w-100 text-center fs-6'>information sur le site</div>
                      <div>
                        <MapCardDemand
                          selected={null}
                          onClick={() => { }}
                          item={selectedBooking.site}
                          contaminants={contaminantsData}
                          concentrations={concentrationData}
                        />
                      </div>
                    </div>
                    <div style={{ height: `${tabPaneWidth}px` }} className='userDetails border-end col d-flex flex-column align-items-center h-100'>
                      <div className='text-uppercase border-bottom w-100 text-center fs-6 mb-2'>origine de la demande</div>
                      <div>
                        <div className='border-bottom mb-2 text-muted d-flex gap-2 align-items-center'>
                          <i class="fas fa-calendar-alt"></i>
                          <span>{moment(new Date(selectedBooking.created_at)).format('DD MMM YYYY à H:mm')}</span>
                        </div>
                        <div className='d-flex justify-content-start align-items-start gap-2'>
                          <div>
                            <img className='userDetails__profile' src={`${selectedBooking.user?.profile ?? require('./../assets/images/profile.png')}`} />
                          </div>
                          <div className='d-flex flex-column'>
                            <span className='userDetails__name'>
                              {selectedBooking.user?.first_name.toLowerCase()}
                              {' '}
                              {selectedBooking.user?.last_name.toLowerCase()}
                            </span>
                            <span className='text-lowercase text-muted small'>
                              {selectedBooking.user?.email}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className='d-flex gap-2'>
                          {selectedBooking.log.map(file => {
                            return <div className='border bg-light p-1 rounded gap-1 d-flex justify-content-center align-items-center'>
                              <i className='fa fa-2x fa-file-pdf' />
                              <div className='text-muted'>
                                Taille : {Number(file.size / 1000000).toFixed(2)} Mo
                              </div>
                              <a href={file.url} target="_blank" rel="noopener noreferrer">
                                <i className='fa fa-external-link' />
                              </a>
                            </div>
                          })}
                        </div>
                      </div>
                    </div>
                    <div style={{ height: `${tabPaneWidth}px` }} className='col d-flex justify-content-between flex-column'>
                      <div className='col d-flex flex-column h-100'>
                        <div className='text-uppercase border-bottom w-100 text-center fs-6 mb-2'>etat du payement</div>
                        <div className='d-flex align-items-center gap-2'>
                          <label for="progress"><small className='fw-bold text-muted'>{formatPrice(selectedBooking.price * selectedBooking.quantity)}</small></label>
                          <progress id="progress" max="100" value={selectedBooking.percent}>{selectedBooking.percent}%</progress>
                          <span className='text-muted small text-primary'>{selectedBooking.percent}%</span>
                        </div>
                        <div className='w-100 text-left mb-1'> <small className='fw-bold text-uppercase text-muted'>montant versée : </small>
                          <small className='text-muted text-primary'> {' '}{formatPrice(selectedBooking.total)}</small>
                        </div>
                        <div className='w-100 text-left mb-2'> <small className='fw-bold text-uppercase text-muted'>montant restant : </small>
                          <small className='text-muted text-primary'> {' '}{formatPrice(selectedBooking.price - selectedBooking.total)}</small>
                        </div>
                        {
                          selectedBooking.payments.length > 0 ?
                            <div className='timeline border-start d-flex flex-column gap-3'>
                              {selectedBooking.payments.map((payment, key) => {
                                return <div className='timeline__item d-flex align-items-center gap-1'>
                                  <div>
                                    {moment(new Date(payment.created_at)).format('DD MMM YYYY')} - {formatPrice(payment.price)}
                                  </div>
                                  <div onClick={(e) => handleOpenInvoice(selectedBooking, payment)} className='timeline_paymentTicket rounded p-1 border d-flex align-items-center justify-content-between gap-2'>
                                    <FaDownload /> <span>facture#{key + 1}</span>
                                  </div>
                                </div>
                              })}
                            </div> : <div className='text-muted'>Aucun payment pour le moment</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {(selectedBooking.status === 'confirmed') && <div className='border-top d-flex justify-content-end gap-3 pt-2'>
                  <button onClick={(e) => { handlePayment() }} disabled={onCancel || onPay} className='btn border pageActionButton text-black-50 fw-bold text-uppercase'>
                    <i class="fas fa-credit-card"></i> {onPay ? 'payement...' : 'faire un payement'}
                  </button>
                </div>}
              </div>
            }
          </div>
          <div className="d-flex justify-content-between w-100 mb-2 align-items-center mt-3">
            <h5 className='fw-bold text-black-50 text-uppercase'></h5>
            <input maxLength={10} placeholder="Recherchez une demande" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search" />
          </div>

          {customTextFilter(bookingData, activeTab, q) ?? [].length > 0 ? <DataTable className=''
            columns={bookingColumns}
            data={customTextFilter(bookingData, activeTab, q)}
            striped
            highlightOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          /> : <NoData />}
        </div>
      </div>
    </div>
    <Modal show={onPay} centered backdrop='static' onHide={() => { setOnPay(false) }}>
      <Modal.Header closeButton>
        <div className='d-flex align-items-center gap-1'>
          <i className='fas fa-credit-card' />
          <span className='text-uppercase'>Ajouter un payement</span>
        </div>
      </Modal.Header>
      <Modal.Body className='pb-0 InfoPage d-flex flex-column'>
        {(selectedBooking !== null && stripePromise) ? <Elements stripe={stripePromise}>
          <div className="alert alert-warning" role="alert">
            Vous pouvez payer intégralement {formatPrice(selectedBooking.price - selectedBooking.total)} ou opter pour un paiement échelonné.
          </div>
          <Tabs className="demandeTabs d-flex justify-content-between" activeKey={activePaymentTab} onSelect={handlePaymentTabChange}>
            <Tab eventKey="payAll" title={<div className='d-flex justify-content-center align-items-center gap-1'><span>Payement intégral</span></div>}>
            </Tab>

            <Tab eventKey="payOwn" title={<div className='d-flex justify-content-center align-items-center gap-1'> <span>Payment par échellon</span></div>}>
            </Tab>
          </Tabs>
          <div style={{ transition: '.2s ease' }} className={"form-group mt-2" + (activePaymentTab === 'payOwn' ? '' : ' opacity-0 d-none')}>
            <input onChange={(e) => { setPrice(e.target.value) }} value={price} type="number" min={5} max={selectedBooking.price - selectedBooking.total}
              class="form-control p-3 rounded" placeholder="Montant à payer en CAD" />
          </div>
          <BookingPaymentForm
            onPaymentSuccess={(data) => {
              setSelectedBooking(data.booking);
              setOnPay(false)
            }}
            amount={price}
            dataForPayment={{ booking_id: selectedBooking.id, price }}
          />
        </Elements> : <CircularLoader />}
      </Modal.Body>
    </Modal>
  </div>
}

export default InformationPage;