import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BookingService from "../../services/BookingService";
import { book } from "./paymentSlice";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getBookings = createAsyncThunk(
    "booking/all",
    async (all=false) => {
        const res = await BookingService.getAll(all);
        return res.data;
    }
);


export const addBooking = createAsyncThunk(
    "booking/add",
    async (item) => {
        const res = await BookingService.add(item);
        return res.data;
    }
);

export const editBooking = createAsyncThunk(
    "booking/edit",
    async (item) => {
        const res = await BookingService.edit(item);
        return res.data;
    }
);


export const removeBooking = createAsyncThunk(
    "booking/remove",
    async (item) => {
        const res = await BookingService.remove(item);
        return res.data;
    }
);


const bookingSlice = createSlice({
    name: "booking",
    initialState,
    extraReducers: {
        [getBookings.fulfilled]: (state, action) => {
            return action.payload
        },
        [addBooking.fulfilled]: (state, action) => {
            if (state == null) {
                state = [action.payload.data];
            } else {
                state.push(action.payload.data)
            }
            return state
        },
        [editBooking.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.map((i) => {
                if (i.id == item.id) return item
                return i
            })
        },
        [removeBooking.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.filter((i) => {
                return i.id !== item.id
            })
        },
        [book.fulfilled]: (state, action) => {
            let bookings = state.slice();
            bookings = bookings.map((booking) => {
                if (booking.id === action.payload.booking.id) {
                    return action.payload.booking;
                }
                return booking;
            })
            return bookings
        },
    },
});

const { reducer } = bookingSlice;
export default reducer;
