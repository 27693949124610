import { useNavigate } from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "./../styles/AddAvailableSolForm.css";
import "./../styles/AddSiteForm.css";
import "react-phone-number-input/style.css";
import { useState, useRef, useEffect} from "react";
import useLanguage from "../../hook/useLanguage";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { addAvailableSol } from "../../redux/slices/availableSolSlice";
import "./../styles/SearchSiteForm.css";
import "./../styles/AddAvailableSolForm.css";
import { motion } from "framer-motion";
import { getMaterialName } from "../../redux/slices/materialNameSlice";



const AddAvailableSolForm = ({ onBack }) => {
  const material_names = useSelector((state) => state.material_name);

  const btnRef = useRef();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const [materialInfos, setMaterialInfos] = useState([]);

  const [materialCount, setMaterialCount] = useState([]);

  const [materialError, setMaterialError] = useState(null);

  const [imagesError, setImagesError] = useState(null);

  const [errorMat, seterrorMat] = useState(null);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setErrors,
    setValues
  } = useFormik({
    initialValues: {
      active: true,
      name: "",
      address: "",
      city: "",
      postal_code: "",
      email: "",
      images: [],
    },

    validate,
    onSubmit: (values) => {
      console.log(errors);
      __handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!material_names) dispatch(getMaterialName());

  }, [material_names]);


  function validate(values) {

    const errors = {};


    if (!values.name) errors.name = "Requis";

    if (!values.city) errors.city = "Requis";

    if (!values.address) errors.address = "Requis";

    if (!values.postal_code) errors.postal_code = "Requis";

    if (values.city.length < 2 && values.city) errors.city = "ville non valide";

    if (!values.email) errors.email = "Requis";

    if ( values.email &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      
    ) {

      errors.email = "email non valide!";
    }

    /* if (materialCount.length == 0 ) {
      errors.materialInfos = "veuillez renseigner les matériaux"
    } */

    if (values.address.length < 4 && values.address)
      errors.address = "addresse non valide";
    /* if (selectedImage == null || selectedImage.length == 0)
      errors.images = "veuillez selectionner au moins une image";
 */
    return errors;
  }

  const handleFilesChange = (e) => {
    // get only the first third image
    setImagesError(null)

    const targetFiles = e.target.files;
    if (targetFiles.length > 3) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = null;
      toast.warn("Vous devez sélectionner au maximum 3 images.");
      return false;
    }
    let imagesFiles = [];
    for (
      let i = 0;
      i < (targetFiles.length > 3 ? 3 : targetFiles.length);
      i++
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imagesFiles.push(e.target.result);
        setSelectedImage(imagesFiles);
        setSelectedFiles((values) => {
          if (values != null) {
            values.push(targetFiles[i]);
            return values;
          }
          return [targetFiles[i]];
        });
      };
      reader.readAsDataURL(targetFiles[i]);
    }
    // e.target.value = null
  };

  const handleRemoveImage = (id) => {
    setSelectedImage((values) => values.filter((item, key) => key != id));
    setSelectedFiles((values) => values.filter((item, key) => key != id));
  };

  const formRef = useRef();

  const dispatch = useDispatch();

  const __handleSubmit = async (values) => {

    if (materialCount.length == 0) {

      setMaterialError("veuillez renseigner les matériaux")

      
    }else if(selectedFiles === null || selectedFiles.length === 0){
      setImagesError("veuillez selectionner au moins une image")
    }else{

      setLoading(true);
      const data = new FormData(formRef.current);
      data.append("material_info", JSON.stringify(materialInfos));
      dispatch(addAvailableSol(data))
        .then((json) => {
          if (json.error) {
            toast.error(
              "Des erreurs ont été rencontrées, veuillez corriger le formulaire et réessayer."
            );
          }else{
            onBack();
            toast.success("Ajout réussi !");
          }
  
        })
        .catch((err) => {
          toast.error(
            "Des erreurs ont été rencontrées, veuillez corriger le formulaire et réessayer."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }


  };


  const handleAddMat = () =>{
    setMaterialError(null)

    let table = []
  
    for (let index = 0; index <= materialCount.length ; index++) {
      table[index] = index + 1
    }
  
    setMaterialCount([...table])
  }

  const handleRemoveMat = (id) =>{
    let table = materialCount
    let table2 = materialInfos
  
    for (let index = 0; index <= materialCount.length ; index++) {
      if (index === id) {
        table.splice(index, 1);
      }
    }

    for (let index = 0; index <= materialCount.length ; index++) {
      if (index === id) {
        table2.splice(index, 1);
      }
    }
  
    setMaterialCount([...table])
    setMaterialInfos([...table2])
  }


  const handleSetMaterialInfos = (value, id, field) =>{

    let table = materialInfos;
    
    for (let index = 0; index <= (id); index++) {
      if (index == id) {
        if (!table[index]){
          table[index] = {}
        }

        if (field === "materialName") {
          table[index].materialName = value
        }else if(field === "quantity"){
          if (value >= 0) { 
            table[index].quantity = value
          }else{
            table[index].quantity = "0"
          }
        }
        else if(field === "price"){
          if (value >= 0) { 
            table[index].price = value
          }else{
            table[index].price = "0"
          }
        }

      }
    }
  
    setMaterialInfos([...table])
  }


/* console.log(materialInfos);
console.log(materialCount); */

  return (
    <div className="LoginForm scale-up-animation shadow-lg rounded col-12">
      <div className="d-flex gap-2 align-items-center p-2">
        <div>
          <i onClick={onBack} className="backButton fa fa-arrow-left-long"></i>
        </div>
        <span className="text-uppercase text-black-90 fw-bold">
          Ajouter un nouveau sol non contaminé
        </span>
      </div>
      <form ref={formRef} onSubmit={handleSubmit} className="p-2 m-2 py-4">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.name}
                onChange={handleChange("name")}
                onBlur={handleBlur}
                type="text"
                name="name"
                id="name"
                className="form-control"
              />
              <label for="name">Nom du site</label>
              {touched.name && errors.name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.name}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.city}
                onChange={handleChange("city")}
                onBlur={handleBlur}
                type="text"
                name="city"
                id="city"
                className="form-control"
              />
              <label for="city">Ville</label>
              {touched.city && errors.city && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.city}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.address}
                onChange={handleChange("address")}
                onBlur={handleBlur}
                type="text"
                name="address"
                id="address"
                className="form-control"
              />
              <label for="address">Addresse</label>
              {touched.address && errors.address && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.address}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.postal_code}
                onChange={handleChange("postal_code")}
                onBlur={handleBlur}
                type="text"
                name="postal_code"
                id="postal_code"
                className="form-control"
              />
              <label for="postal_code">Code Postal</label>
              {touched.postal_code && errors.postal_code && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.postal_code}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur}
                type="text"
                name="email"
                id="email"
                className="form-control"
              />
              <label for="v">Adresse mail</label>
              {touched.email && errors.email && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.email}
                </span>
              )}
            </div>
          </div>

        <div className="d-flex mt-2 gap-4 justify-content-center flex-column mb-4">
          <div className="d-flex gap-4 align-items-center">
            <div>
              <span className="text-uppercase">Plage de materiaux</span>
            </div>
            <motion.div
              onClick={(e)=> handleAddMat()}
              whileTap={{ scale: 1.12 }}
              className="addMat__btn shadow-sm p-2 d-flex align-items-center justify-content-center"
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </motion.div>
          </div>

          {materialCount.length > 0 && materialCount.map((materialCount,index)=>(
            <div key={index} className="flex-row d-flex align-items-center">

              <div className="col-11 row d-flex align-items-center">
                <div className="col-12 col-lg-4">
                  <select
                    style={{width: "90%"}}
                    required
                    name="materialName"
                    className="p-2"
                    value={materialInfos[index]?.materialName}
                    onChange={(e) => handleSetMaterialInfos(e.target.value, index, "materialName")}
                  >
                    <option value="">Choisir un matériau</option>
                    {material_names?.map((material_name, index)=>(
                      <option key={index} value={material_name.id}>{material_name.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-lg-4">
                  <input
                    style={{width: "90%"}}
                    required
                    value={materialInfos[index]?.quantity}
                    name="quantity"
                    onChange={(e) => handleSetMaterialInfos(e.target.value, index, "quantity")}
                    type="number"
                    min="0"
                    placeholder="quantité en tonnes"
                    className="border p-2"
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <input
                    required
                    style={{width: "90%"}}
                    value={materialInfos[index]?.price}
                    name="price"
                    onChange={(e) => handleSetMaterialInfos(e.target.value, index, "price")}
                    type="number"
                    min="0"
                    placeholder="prix par tonne (CAD)"
                    className="border p-2"
                  />
                </div>
              </div>

              <div className="col-1">
                <div className="d-flex justify-content-center align-items-center">
                  <motion.div
                    onClick={(e)=> handleRemoveMat(index)}
                    whileTap={{ scale: 1.12 }}
                    className="addMat__btn shadow-sm p-2 d-flex align-items-center justify-content-center"
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </motion.div>
                </div>
              </div>
              </div>
          )) }

          {materialError && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {materialError}
            </span>
          )}
        </div>

        <div className="input-group d-flex flex-column-reverse align-items-center flex-lg-row gap-lg-5 justify-content-between my-3">
          <input
            multiple
            onChange={handleFilesChange}
            accept="image/*"
            max={3}
            maxLength={3}
            type={"file"}
            className="form-control w-100"
            name="images[]"
            id="image"
          />
          <div className="d-flex justify-content-center gap-2 mb-3 mb-md-0 align-items-center">
            {selectedImage &&
              selectedImage.map((item, key) => {
                return (
                  <img
                    onClick={() => handleRemoveImage(key)}
                    className="previewImage scale-up-animation border rounded shadow p-2"
                    key={key}
                    src={item}
                  />
                );
              })}
          </div>
        </div>
        {imagesError && (
          <span className="form-error">
            <i
              style={{ fontSize: ".8em" }}
              className="fa fa-info-circle"
            ></i>
            {(imagesError)}
          </span>
        )}

<button
          ref={btnRef}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
          disabled= {loading}
        >
          {!loading ?
          <span className="">Enregistrer</span>
          :
          <><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div></>
        }
        </button>
      </form>
    </div>
  );
};

export default AddAvailableSolForm;
