import { useCallback, useMemo } from 'react';

export default function useGeocodeAddress(apiKey) {
  const geocodeAddress = useCallback(
    (address) => {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`;

      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "OK") {
            const { lat, lng } = data.results[0].geometry.location;
            return { lat, lng };
          } else {
            throw new Error(`Geocoding failed: ${data.status}`);
          }
        });
    },
    [apiKey]
  );

  const memoizedGeocodeAddress = useMemo(() => geocodeAddress, [geocodeAddress]);

  return memoizedGeocodeAddress;
}
