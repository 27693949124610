import React, { useState, useEffect, useRef } from 'react'
import "./../styles/SearchSiteForm.css";
import { motion } from 'framer-motion';
import LocalisationInput from './LocalisationInput';
import Tippy from "@tippy.js/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const concentrations = [
  {
    id: 1,
    name: "Aucune",
  },
  {
    id: 2,
    name: "A-B",
  },
  {
    id: 3,
    name: "B-C",
  },
  {
    id: 4,
    name: "C-D",
  },
  {
    id: 5,
    name: "> D",
  }
]

const SearchSiteForm = ({ handleSearch, setSiteDetails, selectedSitesIds, setPreviousSearch, btnRef2 }) => {
  const btnRef = useRef()

  const contaminantList = useSelector((state) => state.contaminant)

  const [qty, setQty] = useState();
  const [typeList, setTypeList] = useState([]);
  const [type, setType] = useState({});
  const [otherType, setOtherType] = useState("");
  const [state, setState] = useState("");
  const [contaminants, setContaminants] = useState([]);
  const [contaminantId, setContaminantId] = useState();
  const [cov, setCov] = useState(false);
  const [bpc, setBpc] = useState(false);
  const [address, setAddress] = useState(null);

  const handleSetType = (value) => {

    if (value.toLowerCase() === "graveleux") {
      if (type?.graveleux === undefined) {
        setType({ ...type, graveleux: true })
        inceaseTypeList(value)
      } else {
        let temp = type;
        delete temp.graveleux;
        setType({ ...temp })
        reduceTypeList(value)
      }
    } else if (value.toLowerCase() === "sableux") {
      if (type?.sableux === undefined) {
        setType({ ...type, sableux: true })
        inceaseTypeList(value)
      } else {
        let temp = type;
        delete temp.sableux;
        setType({ ...temp })
        reduceTypeList(value)
      }
    } else if (value.toLowerCase() === "siliteux") {
      if (type?.siliteux === undefined) {
        setType({ ...type, siliteux: true })
        inceaseTypeList(value)
      } else {
        let temp = type;
        delete temp.siliteux;
        setType({ ...temp })
        reduceTypeList(value)
      }
    } else if (value.toLowerCase() === "argileux") {
      if (type?.argileux === undefined) {
        setType({ ...type, argileux: true })
        inceaseTypeList(value)
      } else {
        let temp = type;
        delete temp.argileux;
        setType({ ...temp })
        reduceTypeList(value)
      }
    }
  }

  const handleTypeListChange = (value) => {

    setOtherType(value)

    if (value.toLowerCase() === "graveleux" || value.toLowerCase() === "sableux"
      || value.toLowerCase() === "siliteux" || value.toLowerCase() === "argileux") {

      handleSetType(value)
    }

  }

  const inceaseTypeList = (value) => {
    let temp = typeList;
    temp.push(value)
    setTypeList(temp)
  }


  const reduceTypeList = (value) => {
    const result = typeList.filter(
      (item) => item !== value
    );

    setTypeList(result)
  }

  const handleAddContaminant = (contaminant, concentration) => {

    if (concentration.id !== 1) {

      const result = contaminants.filter(
        (item) => item.name === contaminant.name
      );


      if (result.length === 0) {
        let temp = contaminants;
        temp.push({ name: contaminant.name, concentration: concentration.name })
        setContaminants(temp)
      } else if (result.length !== 0 && result[0].concentration !== concentration.name) {
        const result2 = contaminants.filter(
          (item) => contaminant.name !== item.name
        );
        result2.push({ name: contaminant.name, concentration: concentration.name })
        setContaminants(result2)
      }

      setContaminantId(contaminant.id)
    } else {
      handleRemoveContaminant(contaminant)
    }
  }

  const handleRemoveContaminant = (item) => {
    const result = contaminants.filter(
      (contaminant) => contaminant.name !== item.name
    );
    setContaminants(result)
  }


  useEffect(() => {
    if (qty && qty < 0) {
      setQty(0)
    }
  }, [qty])


  const Item = ({ item }) => {
    return (
      <Tippy
        content={
          <div className='d-flex' style={{ flexFlow: "column" }}>
            {concentrations.map((child, index) => (
              <motion.button key={index} onClick={e => handleAddContaminant(item, child)}
                className={
                  contaminants.filter(
                    (contaminant) => contaminant.name === item.name
                  ).length !== 0 && contaminants.filter(
                    (contaminant) => contaminant.name === item.name
                  )[0].concentration.toLowerCase() === child.name.toLowerCase() ? "active border bg__smoke-variant " : "bg__smoke-variant border"
                }

                whileTap={{ scale: 0.98 }} style={{ width: "100px", borderRadius: 0 }}

              >{child.name}</motion.button>
            ))}
          </div>

        }
        animation="fade"
        arrow={false}
        interactive={true}
        placement={"bottom"}
        appendTo="parent"
        trigger={"click"}
      >
        <motion.div whileTap={{ scale: 0.97 }}

          className={
            contaminants.filter(
              (contaminant) => contaminant.name === item.name
            ).length !== 0 ? "active contaminant " + className : className + " contaminant"
          }

        >
          {item.name}
        </motion.div>

      </Tippy>
    );
  };

  const handleGoNext = () =>{
    const siteDetails = {
      address,
      qty,
      type: typeList,
      otherType,
      state,
      contaminants,
      cov,
      bpc
    }

    setSiteDetails(siteDetails);
    btnRef2.current.click();
  }


  const handleSearchSite = () => {
    const siteDetails = {
      address,
      qty,
      type: typeList,
      otherType,
      state,
      contaminants,
      cov,
      bpc
    }

    setSiteDetails(siteDetails);

    let isvalid = true;
    let missingFields = "";

    if (!address) {
      isvalid = false;
      missingFields += "adresse, ";
    }
    if (qty?.length === 0) {
      isvalid = false;
      missingFields += "Quantité, ";
    }
    if (typeList.length === 0) {
      isvalid = false;
      missingFields += "Type, ";
    }
    if (state === "") {
      isvalid = false;
      missingFields += "État, ";
    }
    if (contaminants.length === 0) {
      isvalid = false;
      missingFields += "Contaminants, ";
    }

    if (isvalid) {
      handleSearch(siteDetails);
      setPreviousSearch(true)
      btnRef.current.click()
    } else {
      missingFields = missingFields.slice(0, -2); // supprime la dernière virgule et l'espace
      const errorMessage = `Les champs suivants sont manquants ou invalides : ${missingFields}`;
      toast.warning(errorMessage);
    }

  }


  const className = "bg__smoke-variant rounded";

  return (
    <div className="modal fade rounded" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content p-3 search__site-form">
          <div className="modal-body p-3 pb-0">
            <h5 className="modal-title text__smoke" id="exampleModalLabel">{selectedSitesIds?.length == 0 ? 'Recherche de sites' : 'complétez les informations'}</h5>

            <div style={{ height: "70vh" }} className='vertical__scroll-container mt-4 pb-4 gap-3 d-flex flex-column align-items-center '>
              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span className='mb-4'>Adresse</span>

                <LocalisationInput onAddressChange={setAddress} />
              </div>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span>Quantité</span>

                <div className='p-2 mt-3 border__rounded border d-flex flex-row align-items-center justify-content-between'>
                  <input onChange={e => setQty(e.target.value)} value={qty} style={{ width: "60%" }} type="number" min={0} className='border-0 bg__smoke' placeholder='veuillez renseigner la quantité' />
                  <div className="bg__smoke-variant rounded">
                    Tonnes
                  </div>
                </div>
              </div>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span>Type de sol</span>

                <div className='p-2 mt-3 mb-2 rounded scroll__container type d-flex flex-row align-items-center gap-3'>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => handleSetType("Graveleux")} className={type?.graveleux !== undefined && type?.graveleux === true ? "active " + className : className}>
                    Graveleux
                  </motion.div>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => handleSetType("Sableux")} className={type?.sableux !== undefined && type?.sableux === true ? "active " + className : className}>
                    Sableux
                  </motion.div>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => handleSetType("Siliteux")} className={type?.siliteux !== undefined && type?.siliteux === true ? "active " + className : className}>
                    Siliteux
                  </motion.div>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => handleSetType("Argileux")} className={type?.argileux !== undefined && type?.argileux === true ? "active " + className : className}>
                    Argileux
                  </motion.div>
                </div>

                <input
                  /*onChange={e=>handleTypeListChange(e.target.value)} 
                     value={typeList.map((value, index) => {
                      return index != typeList.length - 1 ? value : value 
                    })}  */
                  onChange={e => handleTypeListChange(e.target.value)}
                  value={otherType}

                  style={{ width: "100%", height: '50px' }} type="text" className='border__rounded border bg__smoke ps-2' placeholder='Autres...' />

              </div>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span>Etat physique</span>

                <div className='p-2 mt-3 mb-2 rounded scroll__container d-flex flex-row align-items-center gap-3'>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => setState("Sec")} className={state.toLowerCase() === "sec" ? "active " + className : className}>
                    Sec
                  </motion.div>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => setState("Humide")} className={state.toLowerCase() === "humide" ? "active " + className : className}>
                    Humide
                  </motion.div>

                  <motion.div whileTap={{ scale: 0.97 }} onClick={e => setState("Boueux")} className={state.toLowerCase() === "boueux" ? "active " + className : className}>
                    Boueux
                  </motion.div>
                </div>
              </div>

              <div className='w-100 p-3 bg__smoke rounded__container'>
                <span>Contaminants</span>

                <div className='p-2 mt-3 mb-2 contaminants type rounded scroll__container d-flex flex-row align-items-center gap-3'>

                  {contaminantList?.map((item, index) => (
                    <Item key={index} item={item} />
                  ))}

                </div>

                {/* <input onChange={e=>setContaminant(e.target.value)} value={contaminant.name} style={{width: "100%", height: '50px'}} type="text" className='border__rounded border bg__smoke ps-2' placeholder='Autres...' /> */}
                <div className='p-2 mt-3 d-flex flex-row align-items-center gap-4'>

                  <div className='d-flex flex-row align-items-center gap-1'>
                    <input onClick={e => setCov(!cov)} checked={cov} style={{ cursor: "pointer" }} type="radio" className='bg__smoke ps-2' />
                    <span onClick={e => setCov(!cov)}>COV</span>
                  </div>

                  <div className='d-flex flex-row align-items-center gap-1'>
                    <input onClick={e => setBpc(!bpc)} checked={bpc} style={{ cursor: "pointer" }} type="radio" className='bg__smoke ps-2' />
                    <span onClick={e => setBpc(!bpc)}>BPC</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {selectedSitesIds?.length == 0 ? <div className="modal-footer d-flex align-items-center justify-content-center">
            <motion.button whileTap={{ scale: 1.1 }} type="button" className="btn search__btn rounded-pill"
              onClick={() => handleSearchSite()}
            >Rechercher le site</motion.button>
          </div>
          :
          <div className="modal-footer d-flex align-items-center justify-content-center">
            <motion.button whileTap={{ scale: 1.1 }} type="button" className="btn search__btn rounded-pill"
              onClick={() => handleGoNext()}
            >Continuer</motion.button>
          </div>
        
        }
          <button className='d-none' ref={btnRef} data-bs-toggle="modal" data-bs-target="#exampleModal" ></button>
        </div>
      </div>
    </div>
  )
}

export default SearchSiteForm