import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppContainer from '../components/AppContainer';
import OtpForm from '../components/form/OtpForm';
import RegisterForm from '../components/form/RegisterForm';
import useLanguage from '../hook/useLanguage';
import { UserService } from '../services/UserService';


import './../styles/LoginPage.css'

const OtpPage = () => {
    const [language, t, handleChangeLanguage] = useLanguage()

    return <AppContainer  uniqueChild={true}>
        <OtpForm />
        
    </AppContainer>
}

export default OtpPage;