import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { subscribe } from "./paymentSlice";
import { SubscriptionService } from "../../services/SubscriptionService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;


const initialState = null;

export const getSubscriptions = createAsyncThunk(
  "subscription/all",
  async () => {
      const res = await SubscriptionService.getAll();
      return res.data;
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: {
    [getSubscriptions.fulfilled]: (state, action) => {
      return action.payload
    },
    [subscribe.fulfilled]: (state, action) => {
      if(state){
        const subscriptions = state.slice();
        subscriptions.push(action.payload.subscription);
        return subscriptions
      }
      return [action.payload.subscription]
    },

  },
});

const { reducer } = subscriptionSlice;
export default reducer;