import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ConcentrationService from "../../services/ConcentrationService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getConcentrations = createAsyncThunk(
    "concentration/all",
    async () => {
        const res = await ConcentrationService.getAll();
        return res.data;
    }
);



const concentrationSlice = createSlice({
    name: "concentration",
    initialState,
    extraReducers: {
        [getConcentrations.fulfilled]: (state, action) => {
            return action.payload
        }
    },
});

const { reducer } = concentrationSlice;
export default reducer;