import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// impo rt { Provider } from 'react-redux';
// import store from './redux/store';
import axios from "axios";
/* import { createBrowserRouter, RouterProvider } from "react-router-dom"; */
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoginPage from "./pages/LoginPage";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import OtpPage from "./pages/OtpPage";
import DashboardPage from "./pages/DashboardPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SitePage from "./pages/SitePage";
import InformationPage from './pages/InformationPage';
import ProfilePage from './pages/ProfilePage';
import SideNavBar from './components/navigation/SideNavBar';
import UncontaminatedSites from './pages/UncontaminatedSites';
import DemandPage from "./pages/DemandPage";
import ConfirmOrderPage from './pages/ConfirmOrderPage';
import OrderSitePage from './pages/OrderSitePage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import SubscriptionPage from "./pages/SubscriptionPage";
import SubscriptionPlanPage from "./pages/SubscriptionPlanPage";



const root = ReactDOM.createRoot(document.getElementById("root"));

//router
/* const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/verify-otp",
    element: <OtpPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
  {
    path: "/",
    element: <DashboardPage />,
  },
  {
    path: "/card",
    element: <CardPage />,
  },
  {
    path: "/player",
    element: <PlayerPage />,
  },
  {
    path: "/information",
    element: <InformationPage />,
  },
  {
    path: "/history",
    element: <HistoryPage />,
  },
  {
    path: "/site",
    element: <SitePage />,
  },
]); */

// default axios configs
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;
axios.defaults.withCredentials = true;

/* root.render(
  <Provider store={store}>
    <RouterProvider router={router}>
      <App />
      <ToastContainer
            position="bottom-right"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
    </RouterProvider>
  </Provider>
); */


root.render(
  <Provider store={store}>

    <BrowserRouter>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verify-otp" element={<OtpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />

      </Routes>

      <Routes>
        <Route path="/" element={<SideNavBar><DashboardPage /></SideNavBar>} />
        <Route path="/my-requests" element={<SideNavBar><InformationPage /></SideNavBar>} />
        <Route path="/information/:index" element={<SideNavBar><InformationPage /></SideNavBar>} />
        <Route path="/profile" element={<SideNavBar><ProfilePage /></SideNavBar>} />
        <Route path="/sites" element={<SideNavBar><SitePage /></SideNavBar>} />
        <Route path="/demand" element={<SideNavBar><DemandPage /></SideNavBar>} />
        <Route path="/uncontaminatedSites" element={<SideNavBar><UncontaminatedSites /></SideNavBar>} />
        <Route path="/information/ConfirmOrder/:id" element={<SideNavBar><ConfirmOrderPage /></SideNavBar>} />
        <Route path="/information/order/:id" element={<SideNavBar><OrderSitePage /></SideNavBar>} />
        <Route path="/information/order/success" element={<SideNavBar><OrderSuccessPage /></SideNavBar>} />*
        <Route path="/subscriptionPlan" element={<SideNavBar><SubscriptionPlanPage /></SideNavBar>} />
      </Routes>


      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//ServiceWorker.register()
