import { useState, useEffect } from "react";
import OtpInput from "react18-input-otp";
import useLanguage from "../../hook/useLanguage";
import { UserService } from "../../services/UserService";
import { sleep } from "../../utils/utils";
import './../styles/OtpForm.css'


const OtpForm = () => {

    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState(null);
    const [message, setMessage] = useState(null);
    const [language, t, handleChangeLanguage] = useLanguage();

    const handleChange = (enteredOtp) => {
        setOtp(enteredOtp);
    }


    const handleResendOTPCode = (e)=>{
        e.target.setAttribute("disabled", 'true');
            e.target.innerHTML = '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';
            UserService.resendOTP()
                .then((json) => {
                    setMessage(["Un nouveau code otp a été envoyé!"]);
                    setErrors(null)
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = t('renvoyer le code otp');
                });
    }


    const __handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (otp.length === 4) {
            e.target.setAttribute("disabled", 'true');
            e.target.innerHTML = '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';
            UserService.verifyOTP(otp).
                then((json) => {
                    localStorage.removeItem("otpConfirmation");
                    window.location.href = '/';
                }).catch((err) => {
                    const data = err.response.data
                    if (data.errors) {
                        setMessage(null)
                        setErrors(data.errors);
                    }
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = t('Validate');
                });
        }else{
            
            setMessage(null)
            setErrors(["Veuillez saisir un code à 4 chiffres"]);
        }
    }


    return <><div className="OtpForm LoginForm p-2 p-lg-4">
        <h3 className="text-uppercase mb-3">Validation du compte</h3>
        <div className="alert alert-warning mb-4" role="alert">
            Un code OTP a été envoyé à votre adresse e-mail / numéro de téléphone.<br />Veuillez le saisir pour continuer.
        </div>
        {errors && <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors[0])}</span>}
        {message && <span className='form-success'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(message[0])}</span>}
        <OtpInput containerStyle={'d-flex justify-content-between'} placeholder={0}
            inputStyle={'mx-1 w-100 form-control form-control-otp'} value={otp} onChange={handleChange} numInputs={4} />
        <button onClick={__handleSubmit} type='submit' className='btn btn-primary my-3 mt-4 w-100'>{t('Validate')}</button>
    </div>
    
    <div className='d-flex mt-4 justify-content-start align-items-center gap-3'>
            <div className='text-black-50 fw-500 responsive__info'>Vous n'avez pas recu le code ?</div>
            <button onClick={handleResendOTPCode} className="btn btn-primary responsive__info">renvoyer le code otp</button>
        </div></>
}

export default OtpForm;