import { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import './../styles/DashboardPage.css'
import CircularLoader from '../components/loader/CircularLoader';
import {
    GoSettings,
} from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { getSitesNear } from '../redux/slices/searchSlice';

import useGeocodeAddress from '../hook/useGeocodeAddress';
import SearchSiteForm from './../components/form/SearchSiteForm';
import SiteDetailsCard from './../components/card/SiteDetailsCard';
import LogForm from './../components/form/LogForm';
import AskForSubmissionForm from './../components/form/AskForSubmissionForm';
import EndProcessForm from './../components/form/EndProcessForm';
import { getContaminants } from './../redux/slices/contaminantSlice';
import { toast } from 'react-toastify';
import { getConcentrations } from '../redux/slices/concentrationSlice';
import Selector from '../components/Selector';

import "../styles/UncontaminatedSites.css";
import { getMaterialName } from '../redux/slices/materialNameSlice';
import { getAllAvailableSols, getAvailableSol } from '../redux/slices/availableSolSlice';
import FloatingSols from '../components/map/FloatingSols';
import SearchSolForm from '../components/form/SearchSolForm';
import { getSolsNear } from '../redux/slices/searchedSolsSlice';
import DetailsSolForm from '../components/form/DetailsSolForm';



const DEFAULT_CANADA_POSITION = {
    center: {
        lat: 46.90,
        lng: -71.19
    },
    zoom: 0
};

/* export function getContaminantIdAndName(sites, contaminants) {
    const contaminantLookup = new Map(contaminants.map(contaminant => [contaminant.id, contaminant.name]));

    return [...new Set(sites.flatMap(site => site.contaminants.map(contaminant => contaminant.contaminant)))].map(id => {
        const name = contaminantLookup.get(id);
        if (name !== undefined) {
            return { id, name };
        }
    }).filter(Boolean);
} */

const UncontaminatedSites = () => {
    const btnRef = useRef()
    const btnRef2 = useRef()
    const [activeSearch, setActiveSearch] = useState(false)
    const [onSearch, setOnSearch] = useState(false)
    const [position, setPosition] = useState(null);
    const [positionDenied, setPositionDenied] = useState(false);
    const [lastSearchData, setLastSearchData] = useState(null);
    const [selectedSol, setSelectedSol] = useState(null);
    const [searchedMaterials, setSearchedMaterials] = useState(null);

    const availableSols = useSelector((state) => state.available_sol);
    const searchedSols = useSelector((state) => state.searched_sols);
    const material_names = useSelector((state) => state.material_name);

    const [availableSolData, setAvailableSolData] = useState([]);

    const dispatch = useDispatch();
    const geocodeAddress = useGeocodeAddress(process.env.REACT_APP_SERVER_GOOGLE_MAP_API_KEY);

    useEffect(()=>{
        if (availableSols) {
            const filteredSols = availableSols?.filter(
                (item) => item.active == 1
              );
            setAvailableSolData(filteredSols)
        }
    }, [availableSols])

    useEffect(()=>{
        if (searchedSols) {
            const filteredSols = searchedSols?.filter(
                (item) => item.active == 1
              );
            setAvailableSolData(filteredSols)
        }
    }, [searchedSols])


    const handleApiLoaded = (map, maps) => {
        // put marker in current position
        let marker = new maps.Marker({
            position: { lat: position.center.lat, lng: position.center.lng },
            map,
            title: 'votre position',
        });
        // create the info window
        let contentString = `<div><small>votre position</small></div>`;
        let infowindow = new maps.InfoWindow({
            content: contentString,
        });

        infowindow.open(map, marker);
        // show the info window when the marker is clicked
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        const filteredSols = availableSols?.filter(
            (item) => item.active == 1
          );

          filteredSols.forEach((sol) => {
            let marker = new maps.Marker({
                position: { lat: sol.lat, lng: sol.lng },
                map,
                title: sol.name,
            });
            // create the info window
            let contentString = `<div><small>${sol.name}</small></div>`;
            let infowindow = new maps.InfoWindow({
                content: contentString,
            });

            // show the info window when the marker is clicked
            marker.addListener('click', function () {
                handleSelect(sol);
                setTimeout(() => {
                    infowindow.open(map, marker);
                }, 10);
            });
            infowindow.open(map, marker);
            drawDirection(
                maps,
                map,
                [position.center.lat, position.center.lng],
                [sol.lat, sol.lng],
                sol.id
            )
        })

    }

    const drawDirection = (maps, map, origin, destination, siteId) => {
        const directionsService = new maps.DirectionsService();
        const originD = new maps.LatLng(origin[0], origin[1]);
        const destinationD = new maps.LatLng(destination[0], destination[1]);

        const request = {
            origin: originD,
            destination: destinationD,
            travelMode: maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                new maps.DirectionsRenderer({
                    directions: result,
                    map,
                });
            }
        });
    }

    const handleSearchSol = async (data) => {
       
        setOnSearch(true);
        await geocodeAddress(data.address).then((res) => {
            setPosition(null);
            const searchData = {
                ...res,
                address: data.address,
                ray: data.ray,
            };
            dispatch(getSolsNear(searchData)).unwrap().then(() => {
                setPosition(
                    {
                        center: {
                            ...res
                        },
                        zoom: 0
                    }
                );
            }).catch(() => {
                toast.warn('Une erreur s\'est produite');
            }).finally(() => {
                setOnSearch(false);
            })
        })
    }

    const handleSelect = (sol) => {

         setSelectedSol(sol);
    
    }

    const filterSolsByMaterials = (sols, materialsIds) => {
        if (materialsIds === null || materialsIds.length === 0) return sols;
        return sols.filter(sol => {
            const solsMaterialsIds = JSON.parse(sol.material_info)?.map(mat => parseInt(mat.materialName));
            return materialsIds.reduce((prev, curr) => {
                return prev && solsMaterialsIds.includes(curr);
            }, true);
        });
    }


    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                let lat = pos.coords.latitude;
                let long = pos.coords.longitude;

                let currentPosition = {
                    center: {
                        lat: lat,
                        lng: long
                    },
                    zoom: 0
                };
                setPosition(currentPosition)
            }, function (error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.log("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.log("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        console.log("An unknown error occurred.");
                        break;
                };
                setPosition(DEFAULT_CANADA_POSITION);
            }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
            );
        } else {
            toast.warn('Votre navigateur ne supporte pas la géolocalisation.')
        }
    }, [])


    useEffect(() => {
      !availableSols && position && dispatch(getAllAvailableSols(position.center));
      if (material_names === null) dispatch(getMaterialName());
    }, [availableSols, material_names]);

    
    return <div style={{ height: '100%' }} className="d-flex pt-3 position-relative flex-column justify-content-center w-100 gap-3 align-items-center">
        <div style={{ zIndex: 10 }} className='position-absolute top-0 search__container top-0 d-flex justify-content-end  align-items-center py-3 gap-3'>
            {(!onSearch && availableSolData && material_names ) &&
                <div className='contaminantFilter ms-2 col-8 col-sm-10 col-lg-8'>
                    <Selector
                        selectedId={[]}
                        onChange={setSearchedMaterials}
                        values={material_names}/>
                </div>
            }
            {onSearch && <div className='text-warning d-flex ms-5 bg-light border p-2 rounded'>recherche en cours...</div>}
            <div onClick={()=>setActiveSearch(true)} data-bs-toggle="modal" data-bs-target="#searchSolsModal" style={{ width: "30%", cursor: "pointer", whiteSpace: 'nowrap' }} /* maxLength={10} placeholder="Recherchez un site" */ /* onChange={(e) => setQ(e.target.value)}  */ className="d-none d-lg-flex rounded-pill searchBar bg-transparent form-control mr-sm-2">
                Rechercher un sol
            </div>
            <div  className="me-5 search__icon">
                <div onClick={()=>setActiveSearch(true)} className='d-flex justify-content-center align-items-center'  data-bs-toggle="modal" data-bs-target="#searchSolsModal" style={{ borderRadius: 100,cursor: "pointer", fontSize: "20px", width: "40px", height: "40px", background: "#d5d5d5" }}>

                    <GoSettings />
                </div>
            </div>
        </div>
        {availableSols && position ? <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_SERVER_GOOGLE_MAP_API_KEY }}
            defaultCenter={position.center}
            defaultZoom={position.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
        </GoogleMapReact> : <CircularLoader />}

        {availableSolData && <FloatingSols
        material_names={material_names}
            position={position}
            sites={filterSolsByMaterials(availableSolData, searchedMaterials)}
            onSelect={(sol) => handleSelect(sol)}
        />}


        <button className='d-none' ref={btnRef} data-bs-toggle="modal" data-bs-target="#searchSolsModal" ></button>

        <button className='d-none' ref={btnRef2} data-bs-toggle="modal" data-bs-target="#exampleModal3" ></button>

        <SearchSolForm handleSearch={handleSearchSol}  />

        <DetailsSolForm sol={selectedSol} material_names={material_names} />

    </div>

}

export default UncontaminatedSites;