import React from "react";
import ProfileImg from "../../src/assets/images/profile.png";
import LogoImg from "../../src/assets/images/logo.png";
import { useSelector } from "react-redux";

const ProfileContainer = ({ children }) => {
  const userData = useSelector((state) => state.user);

  return (
    <div
      style={{ height: "100vh" }}
      className="w-100 overflow-hidden profile__container pb-5"
    >
      <header className="bg__smoke row py-3 header__profile shadow-sm">
        <div className="user__profile pe-5 py-2 py-lg-0 col-lg-6 col-sm-12 d-flex align-items-center justify-content-lg-end justify-content-center gap-3">
          <img
            src={userData?.profile ? userData?.profile : ProfileImg}
            alt=""
            style={{ width: "45px", height: "45px" }}
          />
          {userData?.first_name}
        </div>

        <div className=" edit__profile col-lg-6 col-sm-12 d-flex align-items-center  justify-content-lg-start justify-content-center">
          <button
            className="btn rounded-pill w-50 "
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <i className="fa-sharp fa-solid fa-pen"></i> Modifier le profil
          </button>
        </div>
      </header>

      {children}

      <div
        style={{ padding: "11.5px 0" }}
        className=" bg__smoke d-flex position-fixed bottom-0 w-100 align-items-center justify-content-center border-bottom border-top"
      >
        <img
          className=""
          src={LogoImg}
          alt=""
          style={{ height: "auto", width: "50px" }}
        />
      </div>
    </div>
  );
};

export default ProfileContainer;
