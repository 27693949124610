import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import PaymentService from "../../services/PaymentService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const subscribe = createAsyncThunk(
  "payment/subscription",
  async (data) => {
    const res = await PaymentService.processSubscription(data);
    return res.data;
  }
);

export const book = createAsyncThunk(
  "payment/book",
  async (data) => {
    const res = await PaymentService.processBooking(data);
    return res.data;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: {
    [subscribe.fulfilled]: (state, action) => {
      if (state) {
        const payment = state.slice();
        payment.push(action.payload.payment);
        return payment
      }
      return [action.payload.payment]
    },
    [book.fulfilled]: (state, action) => {
      if (state) {
        const payment = state.slice();
        payment.push(action.payload.payment);
        return payment
      }
      return [action.payload.payment]
    },
  },
});

const { reducer } = paymentSlice;
export default reducer;