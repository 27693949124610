import { useNavigate } from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useState, useRef } from "react";
import useLanguage from "../../hook/useLanguage";
import { isValidPhoneNumber } from "react-phone-number-input";
import { UserService } from "../../services/UserService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Alert from "../Alert";
import AddressAutocomplete from "./AddressAutocomplete";



const RegisterForm = () => {
  const btnRef = useRef();

  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();
  const [passwordVisibility, handleTogglePasswordVisibility] =
    usePasswordVisibility(false);

  const [serverError, setServerError] = useState(null);

  const [loading, setLoading] = useState(false);

  const [places, setPlaces] = useState("");

  const { handleSubmit, handleChange, handleBlur, touched, values, errors, setValues } =
    useFormik({
      initialValues: {
        last_name: "",
        first_name: "",
        email: "",
        phone: "",
        company_name: "",
        desk_number: "",
        address: "",
        password: "",
      },
      validate,
      onSubmit: (values) => {
        __handleSubmit();
      },
    });

    console.log(values);


    useEffect(()=>{
        
        setValues({
          ...values,
          address: places
        })
      
    }, [places])

  function validate(values) {
    const errors = {};

    if (!values.last_name) errors.last_name = "Requis";

    if (!values.first_name) errors.first_name = "Requis";
    
    if (!values.email) errors.email = "Requis";

    if (!values.address) errors.address = "Requis";

    if (!values.phone) errors.phone = "Requis";

    if (!values.company_name) errors.company_name = "Requis";

    if (!values.password) errors.password = "Requis";

    if (values.last_name && values.last_name.length < 3)
      errors.last_name = "incorrect last name";

    if (values.first_name && values.first_name.length < 3)
      errors.first_name = "incorrect first name";

    if (values.address && values.address.length < 4)
      errors.address = "addresse non valide";

      if ( values.email &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
        
      ) {

        errors.email = "email non valide!";
      }

    if (values.password && values.password.length < 6) {
      errors.password = "le mot de passe doit dépasser 6 caractères";
    }

    return errors;
  }

  const [isOtpStep, setIsOtpStep] = useState(
    localStorage.getItem("otpConfirmation") !== null
  );

  useEffect(() => {
    if (isOtpStep) {
      navigate("/verify-otp");
    }
  }, [isOtpStep]);

  const __handleSubmit = async (e) => {

    console.log("test");

    setServerError(null);

    setLoading(true);


    UserService.signup(values)
      .then((json) => {
        if (json.data.errors) {
          if (json.data.errors?.email) {
            setServerError(json.data.errors.email);
          } else if (json.data.errors?.phone) {
            setServerError(json.data.errors.phone);
          } else {
            setServerError(json.data.errors[0]);
          }
        } else {
          // set the jwt token in local storage
          localStorage.setItem("authToken", json.data.token);
          localStorage.setItem("authTokenDate", new Date());
          localStorage.setItem("otpConfirmation", "true");
          localStorage.setItem("otpPhone", values.phone);
          localStorage.setItem("otpMail", values.email);
          // setOpenOtpDialog(true);
          navigate("/verify-otp");
          toast.success("Inscription réussie");
        }
      })
      .catch((err) => {
        const data = err.response.data;
        if (data.errors) {
          if (data.errors?.email) {
            setServerError(data.errors.email);
          } else if (data.errors?.password) {
            setServerError(data.errors.password);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="LoginForm  shadow-lg rounded col-12">
      <form className="p-2 m-2 py-4" onSubmit={handleSubmit}>
        <h3 className="mb-4">Inscription</h3>

        <Alert message={serverError} type={"danger"} />

        

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur}
                type="text"
                name="last_name"
                id="last_name"
                className="form-control"
                placeholder={"Nom"}
              />
              {touched.last_name && errors.last_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.last_name)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="first_name"
                placeholder={"prénom"}
              />
              {touched.first_name && errors.first_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.first_name)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <input
            value={values.email}
            type="email"
            onChange={handleChange("email")}
            onBlur={handleBlur}
            className="form-control"
            name="email"
            placeholder={t("Email")}
          />
          {touched.email && errors.email && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>
              {t(errors.email)}
            </span>
          )}
        </div>
        <div className="mb-3 phone__input w-100">
          <PhoneInput
            id="phone"
            name="phone"
            country="ca"
            placeholder={"téléphone"}
            value={values.phone}
            onChange={handleChange("phone")}
            onBlur={handleBlur}
          />
          {touched.phone && errors.phone && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>
              {t(errors.phone)}
            </span>
          )}
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.company_name}
                onChange={handleChange("company_name")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="company_name"
                placeholder={"Nom de la compagnie"}
              />
              {touched.company_name && errors.company_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.company_name)}
                </span>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.desk_number}
                onChange={handleChange("desk_number")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="desk_number"
                placeholder={"Numéro de bureau"}
              />
              {touched.desk_number && errors.desk_number && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.desk_number)}
                </span>
              )}
            </div>
          </div>
        </div>


            <div className="mb-3">
              {/* <input
                value={values.address}
                onChange={handleChange("address")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="address"
                placeholder={"adresse"}
              /> */}

              <AddressAutocomplete address={values.address} places={places} setPlaces={setPlaces} handleBlur={handleBlur} />

              {touched.address && errors.address && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.address)}
                </span>
              )}
            </div>

   

        <div className="input-group">
          <input
            value={values.password}
            onChange={handleChange("password")}
            onBlur={handleBlur}
            type={passwordVisibility ? "text" : "password"}
            className="form-control"
            name="password"
            placeholder={"mot de passe"}
          />
          <span className="input-group-text" id="togglePasswordVisibility">
            <i
              onClick={handleTogglePasswordVisibility}
              className="fa fa-eye"
            ></i>
          </span>
        </div>
        {touched.password && errors.password && (
          <span className="form-error mb-3">
            <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>
            {t(errors.password)}
          </span>
        )}
        <button
          ref={btnRef}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
          disabled= {loading}
        >
          {!loading ?
          <span className="">S'inscrire</span>
          :
          <><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div></>
        }
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
