import { NavLink } from 'react-router-dom';
import AppContainer from '../components/AppContainer';
import LoginForm from '../components/form/LoginForm';
import useLanguage from '../hook/useLanguage';
import './../styles/LoginPage.css'

const LoginPage = () => {
    const [language, t, handleChangeLanguage] = useLanguage()

    return <AppContainer>
        <div className='d-flex flex-column col-lg-7 p-3'>
            <LoginForm />
            <div className='d-flex mt-5 justify-content-start align-items-center gap-3'>
                <div className='text-black-50 fw-bold responsive__info'>Vous n'avez pas de compte?</div>
                <NavLink className="btn btn-primary responsive__info" to="/register">Inscrivez vous</NavLink>
            </div>
        </div>
    </AppContainer>
}

export default LoginPage;