import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CardData from '../components/card/CardData';
import AddSiteForm from '../components/form/AddSiteForm';
import EditSiteForm from '../components/form/EditSiteForm';
import ImageViewer from '../components/ImageViewer';
import CircularLoader from '../components/loader/CircularLoader';
import NoData from '../components/NoData';
import { getConcentrations } from '../redux/slices/concentrationSlice';
import { getContaminants } from '../redux/slices/contaminantSlice';
import { editSite, getSites, removeSite } from '../redux/slices/siteSlice';
import { formatPrice } from '../utils/utils';
import './../styles/SitePage.css'
import { Modal } from 'react-bootstrap';



const SitePage = () => {
    const siteData = useSelector((state) => state.site);
    const contaminantData = useSelector((state) => state.contaminant);
    const concentrationData = useSelector((state) => state.concentration);

    const dispatch = useDispatch();

    const [imageViewer, setImageViewer] = useState(false);
    const [confirm, setConfirm] = useState(null);
    const [q, setQ] = useState(null);
    const [onAdd, setOnAdd] = useState(false);
    const [onEdit, setOnEdit] = useState(false);

    const siteColumns = [
        {
            name: 'Id',
            selector: (row) => {
                return row.site_id
            }
        },
        {
            name: 'Nom',
            selector: (row) => {
                return row.name
            }
        },
        {
            name: 'Type',
            selector: (row) => {
                return row.type
            }
        },
        {
            name: 'Addresse',
            selector: (row) => {
                return row.address
            }
        },
        {
            name: 'Ville',
            selector: (row) => {
                return row.city
            }
        },
        {
            name: 'Prix Unitaire',
            selector: (row) => {
                return formatPrice(row.unit_price)
            },
            sortable: true,
        },
        {
            name: 'Code Postal',
            selector: (row) => {
                return row.postal_code
            }
        },
        {
            name: 'Actif',
            selector: (row) => {
                return <label className="switch">
                    <input type="checkbox" onClick={(e) => handleActiveSite(e, row)} checked={parseInt(row.active) === 1} />
                    <div></div>
                </label>
            },
            sortable: true,
            sortFunction: (a, b) => {
                return parseInt(a.active) - parseInt(b.active)
            }
        },
        {
            name: 'Actions',
            selector: (row) => {
                return <div className="d-flex justify-content-around w-100">
                    <button onClick={() => { setImageViewer(row.images) }} title="visualisez les images du site" style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }} className="actionBtn"><i className="fa fa-eye" aria-hidden="true"></i></button>
                    <button title="editer le site" onClick={() => handleEditSite(row)} style={{ color: "#f5f5f5", backgroundColor: "#22aa22" }} className="mx-1 actionBtn"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                    <button title="supprimer le site" onClick={(e) => handleDeleteSite(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#aa2222" }} className="mx-1 actionBtn"><i className="fa fa-trash" aria-hidden="true"></i></button>
                </div>

            }
        }

    ];

    const handleDeleteSite = (e, site) => {
        e.preventDefault()
        e.stopPropagation()
        setConfirm({
            title: 'Confirmation',
            message: `Voulez vous supprimez le site ${site.name} ?`,
            callback: function (ev) {
                ev.preventDefault()
                ev.stopPropagation()
                ev.target.setAttribute('disabled', 'true')
                ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
                // active user
                dispatch(removeSite({ id: site.id })).then(() => {
                    setConfirm(null)
                    toast.success('Suppression réussie.', { autoClose: 1500 })
                })
            }
        })
    }

    const handleActiveSite = (e, site) => {
        const isActive = parseInt(site.active) === 1;
        e.preventDefault()
        e.stopPropagation()
        setConfirm({
            title: 'Confirmation',
            message: !isActive ?
                (`Voulez vous activer le site ${site.name} ?`)
                : (`Voulez vous désactiver le site ${site.name} ?`),
            callback: function (ev) {
                ev.preventDefault()
                ev.stopPropagation()
                ev.target.setAttribute('disabled', 'true')
                ev.target.innerHTML = "<div class='spinner-grow spinner-grow-sm' role='status'></div>";
                // active user
                dispatch(editSite({ id: site.id, active: isActive ? 0 : 1 })).then(() => {
                    setConfirm(null)
                    toast.success('Modification réussie.', { autoClose: 1500 })
                })
            }
        })
    }

    const handleEditSite = (site) => {
        setOnEdit(site)
    }

    const getUniqueCity = useMemo(
        () => (siteData) => {
            const cities = []
            siteData.map((site) => {
                if (cities.indexOf(site.city) == -1) cities.push(site.city)
            })
            return cities
        }
        , [siteData])


    const customTextFilter = (data, search) => {
        if (search === null) {
            return data
        }
        search = search.toLowerCase()
        return data.filter((val, key) => {

            return val?.name && (val.name.toLowerCase().includes(search) || val.address.toLowerCase().includes(search) || val.city.toLowerCase().includes(search))
        })
    }

    const getAveragePrice = (data) => {
        return data.reduce((prev, site) => {
            return prev + site.unit_price
        }, 0) / (data.length == 0 ? 1 : data.length)
    }

    useEffect(() => {
        if (siteData === null) dispatch(getSites())
        if (contaminantData === null) dispatch(getContaminants())
        if (concentrationData === null) dispatch(getConcentrations())
    }, [])


    return <div>

        {(siteData && concentrationData && concentrationData) ?
            <>
                {!onAdd && !onEdit && <div className='p-0 m-0 p-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='fw-bold text-black-90 text-uppercase'>sites</h4>
                        <button onClick={() => setOnAdd(true)} className='btn border pageActionButton text-black-50 fw-bold text-uppercase'> <i className='fa fa-plus'></i> ajouter</button>
                    </div>

                    <div className='CardDataContainer my-3 d-flex gap-3 justify-content-between p-0 m-0'>
                        <CardData value={`${siteData.length}+`} label={"sites"} icon={<i className="fa fa-area-chart fa-5x" aria-hidden="true"></i>} />
                        <CardData value={`${getUniqueCity(siteData).length}+`} label={"villes reparties"} icon={<i className='fa-solid fa-earth-africa fa-5x text-light'></i>} />
                        <CardData value={`${formatPrice(getAveragePrice(siteData))}`} label={<span>prix moyen <br />d'une tonne</span>} icon={<i className='fa-solid fa-dollar fa-5x text-light'></i>} />
                    </div>
                    <div className="d-flex justify-content-between w-100 mb-2 align-items-center">
                        <h5 className='fw-bold text-black-50 text-uppercase'>listes des sites</h5>
                        <input maxLength={10} placeholder="Recherchez un site" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search"></input>
                    </div>

                    {customTextFilter(siteData, q).length > 0 ? <DataTable className=''
                        columns={siteColumns}
                        data={customTextFilter(siteData, q)}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    /> : <NoData />}

                </div>}
                {onAdd && 
                <div class="d-flex scrollable__content">

                    <AddSiteForm contaminantsList={contaminantData} onBack={() => setOnAdd(false)} />
                </div>
                }
                {onEdit && <div class="d-flex scrollable__content">
                    <EditSiteForm contaminantsList={contaminantData} site={onEdit} onBack={() => setOnEdit(false)} />
                </div>}

            </>
            : <CircularLoader />}
        {imageViewer &&
            <ImageViewer images={imageViewer} onClose={() => { setImageViewer(null) }} open={imageViewer != null} />
        }

        {confirm &&

            <Modal backdrop='static' centered show={confirm ?? false} onHide={(e) => setConfirm(null)}>
                <Modal.Header closeButton>
                    <div className='d-flex gap1 align-items-center gap-1'><i className="fas fa-exclamation-circle fa-1x"></i> {confirm.title}</div>
                </Modal.Header>
                <Modal.Body>
                    {!confirm.onConfirm && <div className="d-flex flex-column">
                        <div>
                            {confirm.message}
                        </div>
                        <button style={{ background: 'var(--primaryColor) !important' }} className='w-50 ms-auto btn btn-primary warning text-uppercase' onClick={(e) => confirm.callback(e)}>Confirmer</button>

                    </div>}
                </Modal.Body>
            </Modal>
        }

    </div>



}

export default SitePage;