
const ImageViewer = ({ onClose, images }) => {

    return <div onClick={onClose} class="ImageViewer d-flex align-items-center justify-content-center">
        <div class="tilt-box-wrap d-flex align-items-center flex-column justify-content-center">
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <span class="t_over"></span>
            <div style={{ overflow: 'hidden', minHeight: '100vh', minWidth: '100vw' }} className="tilt-box d-flex justify-content-center border-0 align-items-center gap-2">
                {images.map((image) => {
                    return <>
                        <img src={image} className="img-fluid w-25" alt={""} /><br />
                    </>
                })}
            </div>
        </div>
    </div>
}

export default ImageViewer;