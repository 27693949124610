import React, { useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import './../styles/DemandPage.css';
import { FaClock, FaSpinner, FaTimes, FaCheck } from 'react-icons/fa';
import { useMemo, useRef } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../components/NoData';
import { editBooking, getBookings } from '../redux/slices/bookingSlice';

import { formatPrice } from '../utils/utils';
import moment from 'moment/moment';
import MapCard from '../components/map/MapCard';
import MapCardDemand from '../components/map/MapCardDemand';
import { getContaminants } from '../redux/slices/contaminantSlice';
import { getConcentrations } from '../redux/slices/concentrationSlice';
import CircularLoader from '../components/loader/CircularLoader';
import { toast } from 'react-toastify';
import { getSubscriptions } from "../redux/slices/subscriptionSlice";

const SubscriptionPlanPage = () => {
    const [activeTab, setActiveTab] = useState('pending');
    const [q, setQ] = useState(null);
    const subscriptionData = useSelector((state) => state.subscription);
    const paymentData = useSelector((state) => state.payment);
    const concentrationData = useSelector((state) => state.concentration);
    const contaminantsData = useSelector((state) => state.contaminant);

    const tabPaneref = useRef(null);
    const [tabPaneWidth, setTabPaneWidth] = useState('auto');


    const [activedSubscription, setactivedSubscription] = useState(null);

    useEffect(() => {
        if (subscriptionData?.length > 0) {
            
            const filteredSubscriptions= subscriptionData?.filter(
              (item) => item.status === "pending"
            );
        
            setactivedSubscription(filteredSubscriptions[0]);
        }
      }, [subscriptionData]);

    const subscriptionColumns = [
        {
            name: 'Date',
            selector: (row) => {
                return moment(row.created_at).format('DD MMM YYYY HH:mm')
            }
        },
        {
            name: 'Forfait',
            selector: (row) => {
                return <div>
                    <div title={row?.subscription_plan?.name}>{row?.subscription_plan?.name}</div>
                </div>
            }
        },
        {
            name: 'Prix',
            selector: (row) => {
                return <div className=''>{formatPrice(row?.subscription_plan?.price)}</div>
            }
        },
        {
            name: 'Validité',
            selector: (row) => {
                return moment(new Date(row.end_Date)).format('DD MMM YYYY HH:mm')
            }
        },
        {
            name: 'Statut',
            selector: (row) => <div style={{ color: "#22aa22" }} className='border p-1 px-2 rounded'>{row.status}</div>
              
            
        },

    ];
    const dispatch = useDispatch();



    useEffect(() => {
        if (subscriptionData === null) dispatch(getSubscriptions())
    }, [])

    useEffect(() => {
        if (!contaminantsData) dispatch(getContaminants());
        if (concentrationData === null) dispatch(getConcentrations())
    }, [concentrationData, contaminantsData]);

    useEffect(() => {

    }, [activedSubscription])

 
    const customTextFilter = (data, search) => {
        if (search === null || search === undefined) {
          return data
        }
        search = search.toLowerCase()
        return data.filter((val, key) => {
    
          return val?.subscription_plan.name && (val.subscription_plan.name.toLowerCase().includes(search) || val.status.toLowerCase().includes(search) || val.end_Date.toLowerCase().includes(search) || val.created_at.toLowerCase().includes(search))
        })
      }


    if (!concentrationData || !contaminantsData) {
        return <CircularLoader />
    }



    return <div>
        <div className='p-3 DemandPage'>

            <div className='d-flex justify-content-between align-items-center'>
                <h4 className='fw-bold text-black-90 text-uppercase'>Forfait d'abonnement</h4>
            </div>
            <div>
              
                <div className="mt-4">
                    <div className='d-flex flex-column align-items-center'>
                        {activedSubscription ?

                            <div style={{ minHeight: '25vh' }} className='p-2 pb-0 col-12 col-lg-6 flex-column d-flex justify-content-center align-items-center bookingDetails rounded border'>
                      
                                    <div style={{ height: `${tabPaneWidth}px` }} className='d-flex justify-content-between align-items-start w-100 row'>
                                        <div ref={tabPaneref} className='col d-flex flex-column align-items-center h-100'>
                                            <div className='text-uppercase border-bottom w-100 text-center fs-6'>informations sur le forfait actuel</div>
                                            <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                      
                                                <div className='w-100 text-left mt-2'>
                                                    <small className='fw-bold text-uppercase text-muted'>Forfait d'abonnement : </small>
                                                                                                    <small className='text-muted text-primary'> {activedSubscription.subscription_plan.name}</small>
                                                </div>

                                                <div className='w-100 text-left '>
                                                    <small className='fw-bold text-uppercase text-muted'>prix : </small>
                                                                                                    <small className='text-muted text-primary'> {formatPrice(activedSubscription.subscription_plan.price)}</small>
                                                </div>

                                                <div className='w-100 text-left'>
                                                    <small className='fw-bold text-uppercase text-muted'>Date d'expiration : </small>
                                                                                                    <small className='text-muted text-primary'> { moment(new Date(activedSubscription.end_Date)).format('DD MMM YYYY HH:mm')}</small>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                         
                               
                            </div>

                            :

                            <div className='w-100 my-5'>

                                <CircularLoader />
                            </div>
                        }
                    </div>
                    <div className="d-flex justify-content-between w-100 mt-5 mb-2 align-items-center mt-3">
                        <h5 className='fw-bold text-black-50 text-uppercase'></h5>
                        <input maxLength={10} placeholder="Rechercher un forfait" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search" />
                    </div>

                    {customTextFilter(subscriptionData, q) ?? [].length > 0 ? <DataTable className=''
                        columns={subscriptionColumns}
                        data={customTextFilter(subscriptionData, q)}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    /> : <NoData />}
                </div>
            </div>
        </div>
    </div>
}

export default SubscriptionPlanPage;