import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`availableSols`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const getAllSols = (data) => {
    return axios.get(`availableSols`, {
        params: data,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const getAllNear = (data) => {
    return axios.get(`availableSols/search`, {
        params: data,
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const ad0d = (item) => {
    const data = item;
    return axios.post('availableSols', data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const add = (item) => {
    const data = item;
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        data.append('_token', response.data);
        return axios.post('availableSols', data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "X-CSRF-TOKEN": response.data
            }
        }).then((data) => {
            return data;
        })
    });
}

const edit = (item) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        return axios.post(`/availableSols/${item?.id ?? item.get('id')}/?_method=PATCH`, item, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "X-CSRF-TOKEN": response.data
            }
        }).then((data) => {
            return data;
        })
    });
}

const remove = (item) => {
    return axios.delete(`/availableSols/${item?.id ?? item.get('id')}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const AvailableSolService = {
    getAll,
    getAllSols,
    getAllNear,
    add,
    edit,
    remove
}

export default AvailableSolService;