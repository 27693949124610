import React from 'react'
import { useParams } from "react-router-dom";
import InformationContainer from '../components/ProfileContainer';
import './../styles/ConfirmOrderPage.css'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import Tippy from "@tippy.js/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';


const concentrations = [
  {
    id: 1,
    name: "Aucune",
  },
  {
    id: 2,
    name: "A-B",
  },
  {
    id: 3,
    name: "B-C",
  },
  {
    id: 4,
    name: "C-D",
  },
  {
    id: 5,
    name: "> D",
  }
]

const contaminants = []

const ConfirmOrderPage = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const className = "bg__smoke-variant rounded";

  const Item = ({ item }) => {
    return (
      <Tippy
        content={
          <div className='d-flex' style={{ flexFlow: "column" }}>
            {concentrations.map((child, index) => (
              <motion.button key={index} 
                className={
                  contaminants.filter(
                    (contaminant) => contaminant.name === item?.name
                  ).length !== 0 && contaminants.filter(
                    (contaminant) => contaminant.name === item?.name
                  )[0].concentration.toLowerCase() === child?.name.toLowerCase() ? "active border bg__smoke-variant-2 " : "bg__smoke-variant-2 border"
                }

                whileTap={{ scale: 0.98 }} style={{ width: "100px", borderRadius: 0 }}

              >{child?.name}</motion.button>
            ))}
          </div>

        }
        animation="fade"
        arrow={false}
        interactive={true}
        placement={"bottom"}
        appendTo="parent"
        trigger={"click"}
      >
        <motion.div whileTap={{ scale: 0.97 }}

          className={
            contaminants.filter(
              (contaminant) => contaminant.name === item?.name
            ).length !== 0 ? "active contaminant " + className : className + " contaminant"
          }

        >
          {item?.name}
        </motion.div>

      </Tippy>
    );
  };

  return (
    <InformationContainer>

      <div style={{}} className='container confirm__order'>
      <div className='mt-5 w-100 d-flex align-items-center justify-content-start gap-5'>
        <div style={{ cursor: "pointer"}} onClick={()=>navigate("/information/3")}>
          <i style={{fontSize: "40px"}} className="fa-solid fa-arrow-left"></i>
        </div>
        <h3 className='m-0'>Recapitulatif</h3>
      </div>

      <div className='responsive__container overflow-scroll'>

        <div className='col-12 container__wrapper d-flex flex-wrap'>

        

          <div className='col-lg-9 col-sm-12 first__bloc mt-5 d-flex flex-column justify-content-center'>

            <div className='w-100 d-flex align-items-center justify-content-between gap-5'>
              <div className='col-6 d-flex align-items-center'>
                <div className='me-4 p-3 px-4 rounded shadow-sm infos__container'>
                  <span style={{fontSize: "12px"}} className=''>Nom du site</span>
                  <h3 className='pt-2'>Let Ville d'Amos</h3>
                </div>
                <div className='p-3 px-4 rounded shadow-sm infos__container'>
                  <span style={{fontSize: "12px"}} className=''>Type</span>
                  <h5 className='pt-2'>Traitement</h5>
                </div>
              </div>
      

            </div>

              <div className='mt-5 d-flex align-items-center'>
                <div className='me-4 p-3 px-4 rounded shadow-sm infos__container'>
                  <span style={{fontSize: "12px"}} className=''>Distance par rapport à vous</span>
                  <h4 className='pt-2'>175km</h4>
                </div>
                <div className='p-3 px-4 rounded shadow-sm infos__container'>
                  <span style={{fontSize: "12px"}} className=''>Adresse</span>
                  <h5 className='pt-2'>5311 Quebec 395 - Amos J9T3A1</h5>
                </div>
              </div>
          </div>

          <div className='col-lg-3 col-sm-12 mt-5 second__bloc  d-flex'>

                <div style={{ height: "85px",cursor: "pointer", background: "#36a35d", color: "#fff", lineHeight: "30px" }} className="shadow-sm rounded_container-price p-3 py-2 border">
                      <span style={{ fontSize: "14px"}}>Prix</span> <br />
                      <h4>1000$/tonnes</h4>
                    </div>
          </div>

          <div className='mt-5 scroll__container d-flex flex-row align-items-center gap-4'>
            <div className='rounded shadow-sm bg-smoke-container p-4'>
              <div className='d-flex justify-content-center mb-2'>
                Quantité
              </div>
                <motion.div whileTap={{ scale: 0.97 }} className={"active " + className}>
                  7000 Tonnes
                </motion.div>
            </div>

            <div className='rounded shadow-sm bg-smoke-container p-4'>
              <div className='d-flex justify-content-center mb-2'>
                Etat physique
              </div>
              <motion.div whileTap={{ scale: 0.97 }} className={"active " + className}>
                  Humide
              </motion.div>
            </div>

            <div className='rounded shadow-sm bg-smoke-container p-4'>
              <div className='d-flex justify-content-center mb-2'>
                Type de sol
              </div>
              <motion.div whileTap={{ scale: 0.97 }} className={"active " + className}>
                  Sableux
              </motion.div>
            </div>

            <div className='rounded shadow-sm bg-smoke-container p-4'>
              <div className='d-flex justify-content-center mb-2'>
                Contaminants
              </div>
              <Item item={{name: "HAP"}} />
            </div>


          </div>


        </div>

        

          <div className="mt-3 btn__container p-4 border-top d-flex align-items-center pe-5">
          <motion.button onClick={()=>navigate("/information/order/1")} whileTap={{ scale: 1.1 }} type="button" className="order__btn-confirm rounded-pill me-5"
          >Commander ce site</motion.button>
          </div>
      </div>

      
      </div>

      
    </InformationContainer>
  )
}

export default ConfirmOrderPage