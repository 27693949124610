import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "./slices/userSlice"
import siteReducer from "./slices/siteSlice"
import searchReducer from "./slices/searchSlice"
import contaminantReducer from "./slices/contaminantSlice.js"
import concentrationReducer from "./slices/concentrationSlice.js"
import bookingReducer from "./slices/bookingSlice.js"
import subscriptionReducer from "./slices/subscriptionSlice"
import paymentReducer from "./slices/paymentSlice"
import subscription_planReducer from "./slices/subscription_planSlice"
import available_solReducer from "./slices/availableSolSlice"
import material_namesReducer from "./slices/materialNameSlice"
import searchedSolsReducer from "./slices/searchedSolsSlice"

export const store = configureStore({
    reducer: {
        user: userReducer,
        site: siteReducer,
        search: searchReducer,
        contaminant: contaminantReducer,
        concentration: concentrationReducer,
        booking: bookingReducer,
        payment: paymentReducer,
        subscription: subscriptionReducer,
        subscription_plan: subscription_planReducer,
        material_name: material_namesReducer,
        available_sol: available_solReducer,
        searched_sols: searchedSolsReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
})

setupListeners(store.dispatch)  