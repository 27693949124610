import CircleGroup from '../components/CircleGroup';
import Navbar from '../components/navigation/Navbar';
import useLanguage from '../hook/useLanguage';
import './styles/AppContainer.css'


const AppContainer = ({ uniqueChild = false, ...props }) => {
    const [language, t, handleChangeLanguage] = useLanguage()

    return <div className='AppContainer'>
        <div className='container-fluid p-0  align-items-center d-flex justify-content-center'>
            <div className='AppContainer__content mt-5 mb-5  w-100 d-flex justify-content-center flex-column-reverse flex-lg-row'>
                
                <div className='p-0 m-0 w-100 d-flex justify-content-center flex-column gap-2 align-items-center'>
                  <img className='col-5 col-lg-3' src='./logo.png'/>
                    {props.children}
                </div>
            </div>
        </div>
    </div>;
}

export default AppContainer;