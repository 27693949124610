import React, { useState, useEffect, useRef } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaSitemap,
    FaDollarSign,
    FaInfo,
    FaClipboardList,
    FaBox,
    FaUserFriends,
    FaBookOpen,
} from "react-icons/fa";
import {
    BiLogOut,
} from "react-icons/bi";
import {
    BsClockHistory,
} from "react-icons/bs";
import { NavLink, useNavigate } from 'react-router-dom';

import Logo from "../../assets/images/logo.png"

import "../styles/SideNavBar.css"
import { getCurrentUser, logoutCurrentUser } from './../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import useAuthToken from '../../hook/useAuthToken';
import CircularLoader from '../loader/CircularLoader';


const SideNavBar = ({ children }) => {
    const year = new Date().getFullYear();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/",
            name: "Sols contaminés",
            icon: <i className="fa-solid fa-earth-americas"></i>
        },
        {
            path: "/uncontaminatedSites",
            name: "Sols non contaminés",
            icon: <i className="fa-solid fa-mountain-sun"></i>
        },
        {
            path: "/sites",
            name: "Sites",
            icon: <FaSitemap />
        },
        {
            path: "/my-requests",
            name: "Mes demandes",
            icon: <FaClipboardList />
        },
        {
            path: "/subscriptionPlan",
            name: "Forfait",
            icon: <FaBox />
        },
        {
            path: "/profile",
            name: "Profil",
            icon: <FaUserAlt />
        },
        {
            path: "/demand",
            name: "Demandes",
            icon: <FaBookOpen />
        },
        /* {
            path: "/users",
            name: "Utilisateurs",
            icon: <FaUserFriends />
        }, */

    ];

    const restrictedPaths = [
        '/demand',
        '/sites',
        '/users'
    ];

    const isClient = typeof window === "object";
    const userData = useSelector((state) => state.user);
    const authToken = useAuthToken();
    const navigate = useNavigate();


    useEffect(() => {
        if (!authToken) {
            navigate('/login')
        }
        if (!userData) {
            dispatch(getCurrentUser()).unwrap().then((data) => {

                if (data) {
                    if (data.subscription_plan == null) {
                        navigate('/subscription');
                    }
                } else {
                    navigate('/login');
                }
            }).catch(() => navigate('/login'));
        } else {
            if (userData.subscription_plan == null) {
                navigate('/subscription');
            }
        }
    }, []);

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount


    useEffect(() => {
        //console.log(windowSize);
        if (windowSize.width <= 793) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }

    }, [windowSize])

    const handleLogout = () => {
        localStorage.clear();
        dispatch(logoutCurrentUser()).then(() => {
            setTimeout(() => { window.location.href = '/login' }, 1500);
        })
    }

    return (<>
        {userData ? <div className="d-flex" style={{ height: "100vh" }}>
            <div style={{ width: isOpen ? "300px" : "50px" }} className={isOpen ? "sidebar overflow-hidden border shadow-sm" : "sidebar overflow-hidden border shadow-sm responsive__sidebar"}>
                <div className="top_section d-flex align-items-center  justify-content-between">
                    <div style={{ display: isOpen ? "block" : "none" }} className="logo"><img className='' src={Logo} /></div>
                    <div style={{ marginLeft: isOpen ? "" : "0px", cursor: "pointer" }} className={isOpen ? "bars" : "responsive__bars bars"}>
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {
                    menuItem.map((item, index) => {
                        if (!userData.admin && restrictedPaths.includes(item.path)) {
                            return null;
                        }
                        return <NavLink style={{ padding: isOpen && "10px 30px" }} to={item.path} key={index} className="link" activeclassname="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                    })
                }

                <motion.div whileTap={{ scale: "1.1" }} onClick={() => handleLogout()} style={{ padding: isOpen && "10px 45px", bottom: "50px", cursor: 'pointer' }} className="link position-fixed d-flex align-items-center justify-content-center" activeclassname="active">
                    <span className='' style={{ fontSize: "18px", paddingBottom: "2px" }}><BiLogOut /></span>
                    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">Déconnexion</div>
                </motion.div>

                <div style={{ opacity: isOpen ? 1 : 0, zIndex: 1000, width: isOpen ? "300px" : "50px", padding: "10px 45px" }} className=" d-flex position-fixed fixed__copyright bottom-0 align-items-center border-bottom border-top">

                    <span className='text-muted' style={{ fontSize: "12px" }}>© {year} ClicSol</span>

                </div>

            </div>
            <main className='' style={{ overflow: "auto" }}>{children}</main>
        </div> : <CircularLoader />}
    </>
    );
};

export default SideNavBar;