import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import './../../styles/SubscriptionPaymentForm.css'
import { motion } from 'framer-motion';
import { formatPrice } from "../../../utils/utils";
import { subscribe } from "../../../redux/slices/paymentSlice";

const PaymentForm = ({ onGoBack, amount, title, stripe_fee, dataForPayment, onPaymentSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const cardFormRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [onPayment, setOnPayment] = useState(false)
    const [onStripePayment, setOnStripePayment] = useState(false)


    const handleStripePayment = async (e, stripe, elements) => {
        e.preventDefault();
        setOnStripePayment(true)
        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement);
        if (error) {
            toast.error("Error: " + error.message)
            setOnStripePayment(false);
        } else {
            // ... SEND to your API server to process payment intent
            const data = {
                "stripe_data": token,
                ...dataForPayment,
            }

            dispatch(subscribe(data))
                .unwrap()
                .then((data) => {
                    if (data.errors) {
                        toast.error(data.errors.join('<br/>'))
                    } else {
                        handlePaymentSuccess(data)
                    }
                })
                .catch((err) => {
                    toast.error("Une erreur s'est produite durant le payement, veuillez vérifier vos informations, votre solde et réessayer svp.")
                }).finally(() => {
                    setOnStripePayment(false)
                })
        }

    };

    const handlePaymentSuccess = (data) => {
        // rediriger l'utilisateur et afficher sa carte
        navigate('/');
    }

    return (
        <>
            <form onSubmit={(event) => handleStripePayment(event, stripe, elements)} ref={cardFormRef} className='mobileForm'>
                <div className='CardElement my-4 py-4 form-control'>
                    <CardElement />
                </div>
                <div>
                    <h6 style={{ fontSize: "12px" }} className='mt-3'>Payement sécurisé par <a href='https://www.stripe.com/' style={{ color: "#36a35d", fontWeight: "600" }} className=''>Stripe</a></h6>

                </div>
                <div style={{ height: "10vh" }} className="mt-3 btn__container border-top d-flex align-items-center">
                    <motion.button disabled={!stripe || onStripePayment} whileTap={{ scale: 1.1 }} type="submit" className="order__btn rounded-pill"
                    >
                        {!onStripePayment ?
                            <span className="">Payer {formatPrice(amount)} </span>
                            :
                            <>payement en cours <div className="spinner-grow spinner-grow-sm" role="status"> <span className="visually-hidden">Loading...</span></div></>
                        }

                    </motion.button>
                </div>
            </form>

        </>
    );
}

const SubscriptionPaymentForm = (props) => (
    <PaymentForm {...props} />
);

export default SubscriptionPaymentForm;