
import { NavLink, useNavigate} from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useState, useRef } from "react";
import useLanguage from "../../hook/useLanguage";

import { isValidPhoneNumber } from "react-phone-number-input";

import { updateUser} from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from './../../redux/slices/userSlice';
import { motion } from "framer-motion";

import ProfileDefault from "../../assets/images/profile.png"

import Alert from "../Alert";
import AddressAutocomplete from "./AddressAutocomplete";


const Profil_Form = () => {
  const btnRef = useRef();
  const profilRef = useRef()

  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userData) dispatch(getCurrentUser());
  }, []);

  const [message, setMessage] = useState(null);

  const [serverError, setServerError] = useState(null);

  const [loading, setLoading] = useState(false);
  

  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();

  const [profile, setProfile] = useState(null)

  const [places, setPlaces] = useState("");

  
  const { handleSubmit, handleChange, handleBlur, touched, values, errors, setValues } =
    useFormik({
      initialValues: {
        last_name: "",
        first_name: "",
        email: "",
        phone: "",
        company_name: "",
        desk_number: "",
        address: "",
      },
      validate,
      onSubmit: (values) => {
        __handleSubmit()
      },
    });

    console.log(values);


    useEffect(()=>{
        
        setValues({
          ...values,
          address: places
        })
      
    }, [places])

    

    function validate(values) {
        
        const errors = {};
    
        if (!/^[a-zA-Z]{3,}$/.test(values.last_name) && values.last_name?.length > 0)
          errors.last_name = "incorrect last name";
    
        if (!/^([a-zA-Z]){3,}$/.test(values.first_name) && values.first_name?.length > 0)
          errors.first_name = "incorrect first name";
    
    
        if (values.address?.length < 4 && values.address?.length > 0) errors.address = "addresse non valide";
    
        if (isValidPhoneNumber(values.phone) && values.phone?.length > 0) //errors.phone = "telephone non valide";
    
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email) &&
          values.email?.length > 0
        ) {
          errors.email = "email non valide!";
        }
    
        if (values.password?.length < 6 && values.password?.length > 0) {
          errors.password = "le mot de passe doit dépasser 6 caractères";
        }
    
        return errors;
      }


  const __handleSubmit = async (e) => {

    setLoading(true);

      let test = false;
      const formData = new FormData()

      if (values.last_name?.length > 0) {
        formData.append('last_name', values.last_name);
        test = true;
      }

      if (values.first_name?.length > 0) {
        formData.append('first_name', values.first_name);
        test = true;
      }

      if (values.email?.length > 0) {
        formData.append('email', values.email);
        test = true;
      }

      if (values.phone?.length > 0) {
        formData.append('phone', values.phone);
        test = true;
      }

      if (values.company_name?.length > 0) {
        formData.append('company_name', values.company_name);
        test = true;
      }

      if (values.desk_number?.length > 0) {
        formData.append('desk_number', values.desk_number);
        test = true;
      }

      if (values.desk_number?.length > 0) {
        formData.append('desk_number', values.desk_number);
        test = true;
      }

      if (values.address?.length > 0) {
        formData.append('address', places);
        test = true;
      }

      if (profile != null) {
        formData.append('profile', profile);
        test = true;
      }
    
      if (test === true) {

        setServerError(null);

        dispatch(updateUser(formData))
        .then((json) => {
  
          if (json.data.errors) {
            if (json.data.errors?.email) {
              setServerError(json.data.errors.email);
            } else if (json.data.errors?.phone) {
              setServerError(json.data.errors.phone);
            } else {
              setServerError(json.data.errors[0]);
            }
          } else {
            
          }
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            if (data.errors?.email) {
              setServerError(data.errors.email);
            } else if (data.errors?.password) {
              setServerError(data.errors.password);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
      }else{
        setLoading(false);
        setServerError("Aucune modification apportée!");
      }
  };

  const handleChangeProfile = (e) =>{
    setProfile(e.target.files[0])
  }

  return (
    <div className="LoginForm col-12">
      <form className=" m-2 " onSubmit={handleSubmit}>

      <Alert message={message} type={"success"} />

      <Alert message={serverError} type={"danger"} />

        <div className="profile__avatar d-flex align-items-center justify-content-center">
          <motion.img onClick={e=>profilRef.current.click()} whileTap={{scale: "1.1"}} src={userData?.profile && !profile ? userData?.profile : profile != null ? URL.createObjectURL(profile) : ProfileDefault} style={{width: "75px", height: "75px", cursor: "pointer"}}/>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
              placeholder={userData?.last_name}
                value={values.last_name}
                onChange={handleChange("last_name")} onBlur={handleBlur}
                type="text"
                name="last_name"
                id="last_name"
                className="form-control"
                
              />
              {touched.last_name && errors.last_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.last_name)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
              placeholder={userData?.first_name}
                value={values.first_name}
                onChange={handleChange("first_name")} 
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="first_name"
                
              />
              {touched.first_name && errors.first_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.first_name)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <input
          placeholder={userData?.email}
            value={values.email}
            type="email"
            onChange={handleChange("email")} 
            onBlur={handleBlur}
            className="form-control"
            name="email"
            
          />
          {touched.email && errors.email && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>
              {t(errors.email)}
            </span>
          )}
        </div>
        <div className="mb-3 phone__input w-100">
          <PhoneInput
            id="phone"
            name="phone"
            value={values.phone}
            onChange={
              
                    handleChange("phone")
                
            }
            onBlur={handleBlur}
            placeholder={userData?.phone}
          />
          {touched.phone && errors.phone && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>
              {t(errors.phone)}
            </span>
          )}
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.company_name}
                onChange={handleChange("company_name")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="company_name"
                placeholder={userData?.company_name}
              />
              {touched.company_name && errors.company_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.company_name)}
                </span>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={values.desk_number}
                onChange={handleChange("desk_number")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="desk_number"
                placeholder={userData?.desk_number ? userData?.desk_number : "Numéro de bureau"}
              />
              {touched.desk_number && errors.desk_number && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.desk_number)}
                </span>
              )}
            </div>
          </div>
        </div>


            <div className="mb-3">
              {/* <input
                value={values.address}
                onChange={handleChange("address")}
                onBlur={handleBlur}
                type="text"
                className="form-control"
                name="address"
                placeholder={userData?.address}
              /> */}

              <AddressAutocomplete address={values.address} places={places} setPlaces={setPlaces} previous_address={userData?.address} handleBlur={handleBlur} />

              {touched.address && errors.address && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {t(errors.address)}
                </span>
              )}
            </div>

   

        <input onChange={e=>handleChangeProfile(e)} ref={profilRef} type="file" className="d-none" name="profile"
                
        />
      
        <button
          ref={btnRef}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
        >
          {!loading ?
          <span className="">Modifier</span>
          :
          <><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div></>
        }
        </button>
      </form>
    </div>
  );
};

export default Profil_Form;
