import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AvailableSolService from "../../services/AvailableSolService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getAvailableSol = createAsyncThunk(
    "availableSol",
    async () => {
        const res = await AvailableSolService.getAll();
        return res.data;
    }
);


export const getAllAvailableSols = createAsyncThunk(
    "availableSol/all",
    async (params) => {
        const res = await AvailableSolService.getAllSols(params);
        return res.data;
    }
);


export const addAvailableSol = createAsyncThunk(
    "availableSol/add",
    async (item) => {
        const res = await AvailableSolService.add(item);
        return res.data;
    }
);

export const editAvailableSol = createAsyncThunk(
    "availableSol/edit",
    async (item) => {
        const res = await AvailableSolService.edit(item);
        return res.data;
    }
);


export const removeAvailableSol = createAsyncThunk(
    "availableSol/remove",
    async (item) => {
        const res = await AvailableSolService.remove(item);
        return res.data;
    }
);


const availableSolSlice = createSlice({
    name: "availableSolSite",
    initialState,
    extraReducers: {
        [getAvailableSol.fulfilled]: (state, action) => {
            return action.payload
        },
        [getAllAvailableSols.fulfilled]: (state, action) => {
            return action.payload
        },
        [addAvailableSol.fulfilled]: (state, action) => {
            if (state == null) {
                state = [action.payload.data];
            } else {
                state.push(action.payload.data)
            }
            return state
        },
        [editAvailableSol.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.map((i) => {
                if (i.id == item.id) return item
                return i
            })
        },
        [removeAvailableSol.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.filter((i) => {
                return i.id !== item.id
            })
        },
    },
});

const { reducer } = availableSolSlice;
export default reducer;