import { useNavigate } from "react-router-dom";
import "./../styles/LoginForm.css";
import "./../styles/AddSiteForm.css";
import "react-phone-number-input/style.css";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from "react-redux";
import { addSite } from "../../redux/slices/siteSlice";
import ContaminantSelect from "../ContaminantSelect";
import "./../styles/SearchSiteForm.css";

import { motion } from 'framer-motion';
import Tippy from "@tippy.js/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

const className = "bg__smoke-variant site__add rounded";

const concentrations = [
  {
    id: 1,
    name: "Aucune",
  },
  {
    id: 2,
    name: "A-B",
  },
  {
    id: 3,
    name: "B-C",
  },
  {
    id: 4,
    name: "C-D",
  },
  {
    id: 5,
    name: "> D",
  }
]

const AddSiteForm = ({ onBack, contaminantsList, concentrationsData, contaminantsData }) => {
  const siteData = useSelector((state) => state.site);
  const [selectContaminants, setSelectContaminants] = useState([])

  const btnRef = useRef();

  const navigate = useNavigate();

  const [selectImage, setSelectedImage] = useState(null);
  const [selectFiles, setSelectedFiles] = useState(null);

  const [contaminants, setContaminants] = useState([]);
  const [contaminantId, setContaminantId] = useState();

  const [contaminantsError, setContaminantsError] = useState(null);

  const [imagesError, setImagesError] = useState(null);

  const [loading, setLoading] = useState(false);


  const { handleSubmit, handleChange, handleBlur, touched, values, errors, setErrors, setValues } =
    useFormik({
      initialValues: {
        site_id: "",
        name: "",
        address: "",
        city: "",
        postal_code: "",
        images: [],
        email: '',
        cov: false,
        bpc: false,
        unit_price: 0
      },
      validate,
      onSubmit: (values) => {
        __handleSubmit(values)
      },
    });



  function validate(values) {

    const errors = {};

    if (!values.site_id) errors.site_id = "Requis";
    if (!values.email) errors.email = "Requis";

    if (!values.name) errors.name = "Requis";
    if (isNaN(values.unit_price)) errors.unit_price = "le prix est requis";

    if (!values.city) errors.city = "Requis";

    if (!values.address) errors.address = "Requis";

    if (!/^([a-zA-Z0-9]){3,}$/.test(values.postal_code))
      errors.postal_code = "incorrect postal code";

    if (values.city.length < 2 && values.city) errors.city = "ville non valide";
    if (contaminants.length === 0) errors.contaminants = "veuillez entrer un contaminant";

    if (values.address.length < 4 && values.address) errors.address = "addresse non valide";
    if (selectFiles === null || selectFiles.length === 0) errors.images = "veuillez selectionner au moins une image"


    return errors;
  }

  const handleFilesChange = (e) => {
    // get only the first third image
    setImagesError(null)
    const targetFiles = e.target.files;
    if (targetFiles.length > 3) {
      e.preventDefault()
      e.stopPropagation()
      e.target.value = null
      toast.warn('Vous devez sélectionner au maximum 3 images.')
      return false;
    }
    let imagesFiles = [];
    for (let i = 0; i < (targetFiles.length > 3 ? 3 : targetFiles.length); i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imagesFiles.push(e.target.result)
        setSelectedImage(imagesFiles)
        setSelectedFiles((values) => {
          if (values != null) {
            values.push(targetFiles[i])
            return values
          }
          return [targetFiles[i]]
        })
      }
      reader.readAsDataURL(targetFiles[i])
    }
    // e.target.value = null
  }

  const handleRemoveImage = (id) => {
    setSelectedImage((values) => values.filter((item, key) => key != id))
    setSelectedFiles((values) => values.filter((item, key) => key != id))
  }

  const formRef = useRef()

  const dispatch = useDispatch()

  const __handleSubmit = async (values) => {

    if (contaminants.length === 0) {

      setContaminantsError("veuillez entrer un contaminant")


    } else if (selectFiles === null || selectFiles.length === 0) {
      setImagesError("veuillez selectionner au moins une image")
    }
    else {
      setLoading(true);

      const data = new FormData(formRef.current)
      data.set('cov', data.get('cov') === 'on' ? 1 : 0);
      data.set('bpc', data.get('bpc') === 'on' ? 1 : 0);
      data.append('contaminants', JSON.stringify(contaminants));
      data.set('email', values.email);
      dispatch(addSite(data)).unwrap()
        .then((json) => {
          onBack()
          toast.success('Ajout réussi !');
        })
        .catch((err) => {
          const data = err.response?.data;
          if (data !== undefined && data.errors) {
            setErrors(data.errors)
            if (data.message) {
              toast.error(
                data.message
              );
            } else {
              toast.error(
                "Des erreurs ont été rencontrées, veuillez corriger le formulaire et réessayer."
              );
            }
          } else {
            toast.error(
              "Des erreurs ont été rencontrées, veuillez corriger le formulaire et réessayer."
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };




  const handleAddContaminant = (contaminant, concentration) => {
    setContaminantsError(null)

    if (concentration.id !== 1) {

      const result = contaminants.filter(
        (item) => item.contaminant === contaminant.name
      );

      if (result.length === 0) {
        let temp = contaminants;
        temp.push({ contaminant: contaminant.name, concentration: concentration.name })
        setContaminants([...temp])
      } else if (result.length !== 0 && result[0].concentration !== concentration.name) {
        const result2 = contaminants.filter(
          (item) => contaminant.name !== item.contaminant
        );
        result2.push({ contaminant: contaminant.name, concentration: concentration.name })
        setContaminants([...result2])
      }

      setContaminantId(contaminant.id)
    } else {
      handleRemoveContaminant(contaminant)
    }
  }

  const handleRemoveContaminant = (item) => {
    setContaminantsError(null)
    const result = contaminants.filter(
      (contaminant) => contaminant.contaminant !== item.name
    );
    setContaminants([...result])

  }


  const Item = ({ item }) => {
    return (
      <Tippy
        content={
          <div className='d-flex' style={{ flexFlow: "column" }}>
            {concentrations.map((child, index) => (
              <motion.button key={index} onClick={e => handleAddContaminant(item, child)}
                className={
                  contaminants.filter(
                    (contaminant) => contaminant.contaminant === item.name
                  ).length !== 0 && contaminants.filter(
                    (contaminant) => contaminant.contaminant === item.name
                  )[0].concentration.toLowerCase() === child.name.toLowerCase() ? "active border bg__smoke-variant site__add " : "bg__smoke-variant border"
                }

                whileTap={{ scale: 0.98 }} style={{ width: "100px", borderRadius: 0 }}

              >{child.name}</motion.button>
            ))}
          </div>

        }
        animation="fade"
        arrow={false}
        interactive={true}
        placement={"bottom"}
        appendTo="parent"
        trigger={"click"}
      >
        <motion.div whileTap={{ scale: 0.97 }}

          className={
            contaminants.filter(
              (contaminant) => contaminant.contaminant === item.name
            ).length !== 0 ? "active contaminant " + className : className + " contaminant"
          }

        >
          {item.name}
        </motion.div>

      </Tippy>
    );
  };


  return (
    <div className="LoginForm scale-up-animation shadow-lg rounded col-12">
      <div className="d-flex gap-2 align-items-center p-2">
        <div><i onClick={onBack} className="backButton fa fa-arrow-left-long"></i></div>
        <span className="text-uppercase text-black-90 fw-bold">Ajouter un nouveau site</span>
      </div>
      <form ref={formRef} onSubmit={handleSubmit} className="p-2 m-2 py-4">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.site_id}
                onChange={handleChange("site_id")} onBlur={handleBlur}
                type="text"
                name="site_id"
                id="site_id"
                className="form-control"
              />
              <label for="site_id">Identifiant du site</label>
              {touched.site_id && errors.site_id && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.site_id)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.name}
                onChange={handleChange("name")} onBlur={handleBlur}
                type="text"
                name="name"
                id="name"
                className="form-control"
              />
              <label for="name">Nom du site</label>
              {touched.name && errors.name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.name)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.city}
                onChange={handleChange("city")} onBlur={handleBlur}
                type="text"
                name="city"
                id="city"
                className="form-control"
              />
              <label for="city">Ville</label>
              {touched.city && errors.city && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.city)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <select onChange={handleChange('type')} value={values.type} className="form-select form-select-sm" name="type" id="type">
                {
                  siteData?.map((site) => {
                    return <option value={site.type} key={site.key}>{site.type}</option>
                  })
                }
              </select>
              <label for="type">Type</label>
              {touched.type && errors.type && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.type)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.address}
                onChange={handleChange("address")} onBlur={handleBlur}
                type="text"
                name="address"
                id="address"
                className="form-control"
              />
              <label for="address">Addresse</label>
              {touched.address && errors.address && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.address)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.postal_code}
                onChange={handleChange("postal_code")} onBlur={handleBlur}
                type="text"
                name="postal_code"
                id="postal_code"
                className="form-control"
              />
              <label for="postal_code">Code Postal</label>
              {touched.postal_code && errors.postal_code && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.postal_code)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="form-floating mb-3">
              <input
                value={values.unit_price}
                step={0.1}
                min={0}
                onChange={handleChange("unit_price")} onBlur={handleBlur}
                type="number"
                name="unit_price"
                id="unit_price"
                className="form-control"
              />
              <label for="address">Prix(par tonne)</label>
              {touched.unit_price && errors.unit_price && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>
                  {(errors.unit_price)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-floating mb-3">
              <input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur}
                type="text"
                name="email"
                id="email"
                className="form-control"
              />
              <label for="email">Adresse mail</label>
              {touched.email && errors.email && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.email}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center">
            <div className="form-check">
              <input
                checked={values.cov}
                onChange={handleChange} onBlur={handleBlur}
                type="checkbox"
                name="cov"
                id="cov"
                className="form-check-input"
              />
              <label className="form-check-label" for="cov">COV</label>
            </div>
            <div className="form-check ms-3">
              <input
                checked={values.bpc}
                onChange={handleChange} onBlur={handleBlur}
                type="checkbox"
                name="bpc"
                id="bpc"
                className="form-check-input"
              />
              <label className="form-check-label" for="bpc">BPC</label>
            </div>
          </div>
        </div>

        <div className="d-flex mt-2 gap-4 justify-content-center flex-column mb-4">
          <span className="text-uppercase">Nature de la contamination</span>

          <div style={{ overflowX: "scroll" }} className='p-2 mt-3 mb-2 contaminants rounded scroll__container d-flex flex-row align-items-center gap-3'>
            {contaminantsList?.map((item, index) => (
              <Item key={index} item={item} />
            ))}

          </div>

          {/* {<ContaminantSelect onChange={setSelectContaminants} defaultValue={selectContaminants} concentrations={concentrationsData} contaminants={contaminantsData} />}
 */}          {contaminantsError && (
            <span className="form-error">
              <i
                style={{ fontSize: ".8em" }}
                className="fa fa-info-circle"
              ></i>
              {(contaminantsError)}
            </span>
          )}
        </div>

        <div className="input-group d-flex flex-column-reverse align-items-center flex-lg-row gap-lg-5 justify-content-between my-3">
          <input multiple onChange={handleFilesChange} accept='image/*' max={3} maxLength={3} type={'file'} className='form-control w-100' name="images[]" id="image" />
          <div className="d-flex justify-content-center gap-2 mb-3 mb-md-0 align-items-center">
            {selectImage && selectImage.map((item, key) => {
              return <img onClick={() => handleRemoveImage(key)} className="previewImage scale-up-animation border rounded shadow p-2" key={key} src={item} />
            })}
          </div>
        </div>
        {imagesError && (
          <span className="form-error">
            <i
              style={{ fontSize: ".8em" }}
              className="fa fa-info-circle"
            ></i>
            {(imagesError)}
          </span>
        )}

        <button
          ref={btnRef}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
          disabled={loading}
        >
          {!loading ?
            <span className="">Enregistrer</span>
            :
            <><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div></>
          }
        </button>

      </form>
    </div>
  );
};

export default AddSiteForm;
