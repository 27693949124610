import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppContainer from '../components/AppContainer';
import RegisterForm from '../components/form/RegisterForm';
import useLanguage from '../hook/useLanguage';

import './../styles/LoginPage.css'

const RegisterPage = () => {
    const [language, t, handleChangeLanguage] = useLanguage()

    return <AppContainer>
        <div className='d-flex flex-column col-lg-7'>
            <RegisterForm />

            <div className='d-flex mt-4 justify-content-start align-items-center gap-3'>
                <div className='text-black-50 fw-bold responsive__info'>{"Vous avez déjà un compte?"}</div>
                <NavLink className="btn btn-primary responsive__info" to="/login">Connexion</NavLink>
            </div>
        </div>
    </AppContainer>
}

export default RegisterPage;