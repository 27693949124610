import axios from "axios"
import { toast } from 'react-toastify';


const getCurrentUser = (token) => {
    return axios.get(`user`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        // console.log(data.data);
        return data;
    })
}

export const UpdateUser = (userToPatch) => {
    //console.log(userToPatch);
    return axios
        .post(`${process.env.REACT_APP_API_BASE_URL}user/update`, userToPatch, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
        })
        .then((data) => {
            // console.log(data);
            toast.success(
                "Votre profil a été mis à jour."
              );
            return data;
        })

}

const signup = async (data) => {

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        return axios({
            url: `${process.env.REACT_APP_API_BASE_URL}signup`,
            method: 'post',
            data: {
                ...data
            },
            headers: {
                "X-CSRF-TOKEN": response.data
            }
        });
    });

}

const signin = async (data) => {

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        return axios({
            url: `${process.env.REACT_APP_API_BASE_URL}signin`,
            method: 'get',
            params: {
                ...data
            },
            headers: {
                "X-CSRF-TOKEN": response.data
            }
        });
    });

}


const verifyOTP = (otp) => {

    return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}verify-otp/${otp}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    });

};

const resendOTP = () => {

    return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}resend-otp`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    });

};
const askNewPasswordLink = (data) => {

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        // console.log(response.data);
        return axios({
            url: `${process.env.REACT_APP_API_BASE_URL}reset-password`,
            method: 'get',
            params: {
                ...data
            },
            headers: {
                "X-CSRF-TOKEN": response.data
            }
        })
    });
};


const changePassword = ({ token, password }) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
        return axios({
            url: `${process.env.REACT_APP_API_BASE_URL}reset-password/${token}/${password}`,
            method: 'get',
            headers: {
                "X-CSRF-TOKEN": response.data
            }
        })
    })
}

export const UserService = {
    getCurrentUser, UpdateUser, signup, signin, verifyOTP, resendOTP, askNewPasswordLink, changePassword
}

