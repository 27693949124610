import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AvailableSolService from "../../services/AvailableSolService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSolsNear = createAsyncThunk(
    "searchedSols/all",
    async (params) => {
        const res = await AvailableSolService.getAllNear(params);
        return res.data;
    }
);


const searchedSolsSlice = createSlice({
    name: "searchedSols",
    initialState,
    extraReducers: {
        [getSolsNear.fulfilled]: (state, action) => {
            return action.payload
        },
    },
});

const { reducer } = searchedSolsSlice;
export default reducer;