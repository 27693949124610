import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserService } from "../../services/UserService";
import { subscribe } from "./paymentSlice";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getCurrentUser = createAsyncThunk(
  "user/current",
  async () => {
    const res = await UserService.getCurrentUser();
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (user) => {
    const res = await UserService.UpdateUser(user);
    return res.data;
  }
);

export const logoutCurrentUser = createAsyncThunk(
  "user/logout",
  async () => {
    return null;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getCurrentUser.fulfilled]: (state, action) => {
      return action.payload
    },
    [subscribe.fulfilled]: (state, action) => {
      return action.payload.user
    },
    [updateUser.fulfilled]: (state, action) => {
      return action.payload.data
    },
    [logoutCurrentUser.fulfilled]: (state, action) => {
      return null
    },
  },
});

const { reducer } = userSlice;
export default reducer;